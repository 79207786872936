import React from 'react'
import {
    Container,
} from '@mui/material';
import { useNavigate } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'
import { useAuth } from '../auth/AuthProvider';

import Loading from '../../parts/Loading';
import MultipleChoiceExam from './MultipleChoiceExam';
import { sbUpsert } from '../../helper/SupabaseController';

// TODO 'updated_at': ((new Date()).toISOString()).toLocaleString('zh-TW')
//https://keizokuma.com/js-array-object-sort/
const sortArray = (arr) => {
    if (typeof arr === 'undefined') { return arr }
    if (Object.keys(arr).length <= 1) { return arr }

    let result = arr.sort(function (a, b) {
        return (new Date(a.next_question_date_time) < new Date(b.next_question_date_time)) ? -1 : 1;  //オブジェクトの昇順ソート
    });
    return result
}

const Benche = () => {
    const { user } = useAuth();
    const tempCards = JSON.parse(localStorage.getItem('cards'));
    const [cards, setCards] = React.useState(tempCards)
    const navigate = useNavigate()


    const blockBrowserBack = React.useCallback(() => {
        window.history.go(1)
    }, [])

    React.useEffect(() => {
        // 直前の履歴に現在のページを追加
        window.history.pushState(null, '', window.location.href)

        // 直前の履歴と現在のページのループ
        window.addEventListener('popstate', blockBrowserBack)

        // クリーンアップは忘れない
        return () => {
            window.removeEventListener('popstate', blockBrowserBack)
        }
    }, [blockBrowserBack])

    // const [loading] = React.useState(true)

    // React.useEffect(() => {
    //     setCards(JSON.parse(localStorage.getItem('cards')))
    // }, [])

    // const [tempOptions] = React.useState(
    //     tempCards.map((card, key) => {
    //         if (card.correct_options) {
    //             return card.correct_options
    //         }
    //     }).join().split(",").filter((item) => {
    //         return item !== ''
    //     })
    // )
    // const [tempOptions] = React.useState(
    //     tempCards.map((card, key) => (
    //         card.correct_options &&
    //         card.correct_options
    //     )).join().split(",").filter((item) => {
    //         return item !== ''
    //     })
    // )
    const tempOptions = (
        tempCards.map((card, key) => (
            card.correct_options &&
            card.correct_options
        )).join().split(",").filter((item) => {
            return item !== ''
        })
    )

    const addHistory = async (res, type = "update") => {
        const { error } = await sbUpsert("history", {
            card_id: res.card.card_id,
            correct: res.correct,
            selected: res.selectValue,
            result: res.result,
            created_by: user.id,
            type: type
        })
        error && toast.error(error.message, ToastStyle.error)
        // toast.success("成功", ToastStyle.success)
    }

    const updateSbCard = async (card) => {
        const { error } = await sbUpsert("card",
            { ...card }

        )
        error && toast.error(error.message, ToastStyle.error)

    }



    // React.useEffect(() => {
    //     setTempOptions(() => {
    //         const tempOption = cards.map((card, key) => {
    //             if (card.correct_options) {
    //                 return card.correct_options
    //             }
    //         })
    //         return tempOption.join().split(",").filter((item) => {
    //             return item !== ''
    //         })
    //     })
    //     setLoading(false)
    // }, [])
    // const tempOptions = () => {
    //     const tempOption = cards.map((card, key) => {
    //         if (card.correct_options) {
    //             return card.correct_options
    //         }
    //     })
    //     // 空欄は除く
    //     return tempOption.join().split(",").filter((item) => {
    //         return item !== ''
    //     })
    // }

    // // https://qiita.com/itachi/items/4184b2afc35b55b45568
    const deleteLocalCard = (cards, res) => {

        const newCards = sortArray(cards.filter((cards, index) => (cards.card_id !== res.card.card_id)))
        const mistakeCards = newCards.filter(card => card.mistake === true)
        const notmistakeCards = newCards.filter(card => card.mistake !== true)


        if (typeof mistakeCards === 'undefined' || mistakeCards.length === 0) {
            setCards(() => newCards)
        } else if (new Date(mistakeCards[0].next_question_date_time) < new Date()) {
            setCards(() => [...mistakeCards, ...notmistakeCards])
        } else {
            setCards(() => newCards)
        }
    }

    const updateLocalCard = (cards, res) => {

        const newCards = sortArray(cards.map((obj) =>
            obj.card_id === res.card.card_id ?
                { ...res.card } : obj))
        const mistakeCards = newCards.filter(card => card.mistake === true)
        const notmistakeCards = newCards.filter(card => card.mistake !== true)


        // ミステイクカードをnext question timeでソートして一番直近のtimeが new Dateより小さければ、
        // ミステイクカードをcardsにセットする。それ以外は、newCardsをセットする。 
        if (typeof mistakeCards === 'undefined' || mistakeCards.length === 0) {
            setCards(() => sortArray(newCards))
        } else if (new Date(mistakeCards[0].next_question_date_time) < new Date()) {
            setCards(() => [...mistakeCards, ...notmistakeCards])
        } else {
            setCards(() => newCards)
        }
    }


    const handleChange = (res) => {
        if (res.correct === res.selectValue) {
            if (res.card.mistake === false || res.card.mistake == null) {
                // カーソルを１つ進める
                // setCursor((cursor) => cursor + 1)
                // 正解、明日以降へセット
                const defaultInterval = res.card.question_interval * res.card.question_level
                const nextQuestionInterval = defaultInterval <= 60 * 60 * 12 ? 60 * 60 * 12 : res.card.question_interval * res.card.question_level

                res.card.question_interval = nextQuestionInterval // どっち？？defaultInterval
                var nowDate = new Date()
                nowDate.setSeconds(nowDate.getSeconds() + nextQuestionInterval)
                res.card.next_question_date_time = nowDate
                // cardsから削除


                if (res.cardUpdate) {
                    deleteLocalCard(cards, res)
                } else {
                    addHistory(res, "correct")
                    updateSbCard(res.card)
                }
                // db 更新
                // -----------------ここスパベース

            } else {
                // 正解、インターバル設定
                const interval = res.card.question_interval * res.card.question_level
                res.card.question_interval = interval
                // インターバルに応じてmistakeをfalse
                if (interval >= 15) { res.card.mistake = false }
                const nowDate = new Date()
                nowDate.setSeconds(nowDate.getSeconds() + interval)
                res.card.next_question_date_time = nowDate


                if (res.cardUpdate) {
                    updateLocalCard(cards, res)
                } else {
                    addHistory(res, "update")
                    updateSbCard(res.card)
                }
                // db 更新
                // -----------------ここスパベース
            }
        } else {
            // 不正解、インターバル設定
            const interval = 5
            res.card.question_interval = interval
            res.card.mistake = true
            const nowDate = new Date()
            nowDate.setSeconds(nowDate.getSeconds() + interval)
            res.card.next_question_date_time = nowDate

            if (res.cardUpdate) {
                updateLocalCard(cards, res)
            } else {
                addHistory(res, "incorrect")
                updateSbCard(res.card)
            }
            // db更新
            // -----------------ここスパベース

        }
    }

    // if (loading === true) { <Loading /> }
    // if (!cards) { <Loading /> }
    // if (typeof cards === 'undefined') { <Loading /> }
    // if (typeof cards[0] === 'undefined') { <Loading /> }

    return (
        <Container component="main" maxWidth="xs">
            <Toaster />
            {/* {Object.keys(cards).length <= cursor ?
                navigate(`/workout/`)
                : */}
            <MultipleChoiceExam
                // card={selectCard === "cards" ? cards[cursor] || navigate(`/workout/`) : mistakeCards[0] || navigate(`/workout/`)}
                card={cards[0] || navigate(`/workout/`)}
                options={tempOptions}
                progress={`${Object.keys(cards).length}`}
                onChange={(res) => {
                    // カードの共通の内容を更新する
                    res.card.updated_at = new Date()
                    // setState((state) => ({ ...state, [e.target.id]: e.target.value }));
                    res.card.history = { ...res.card.history || "", [new Date()]: { result: res.result, selectValue: res.selectValue, inquiryOptions: res.inquiryOptions } }
                    // res.card.history = [...res.history || "",
                    // { [new Date()]: { result: res.result, selectValue: res.selectValue, inquiryOptions: res.inquiryOptions } }]
                    handleChange(res)

                    // res.card.mistake || null ?
                    //     handleOnChangeMistake(res) :
                    //     handleOnChange(res)
                    // judgeTheNextCard(res)

                    // if (typeof mistakeCards === 'undefined') {
                    //     setSelectCard(cards[cursor])
                    // } else if (mistakeCards.length == 0) {
                    //     setSelectCard(cards[cursor])
                    // } else {
                    //     const mistakeCard = mistakeCards[Object.keys(mistakeCards)[0]]
                    //     if (new Date(mistakeCard.next_question_date_time) < new Date()) {
                    //         setSelectCard(mistakeCard)
                    //     } else {
                    //         setSelectCard(cards[cursor])
                    //     }
                    // }

                }
                }
            />
            {/* } */}
        </Container>
    )
}

export default Benche