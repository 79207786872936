import React from 'react'
import {
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Fab, AppBar, IconButton, Toolbar, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Create from './Create';
// import { history } from "react-router-dom"


const DialogButtonForCreate = (props) => {
    const componentRef = React.useRef();
    const [open, setOpen] = React.useState(false);
    const { item, renderInput } = props;

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }


    // ブラウザの戻るを抑制
    const preventBack = (e) => {
        console.log(" window.history.go(1)")
        // alert('ブラウザバックを使わないでください。');
        window.removeEventListener(`popstate`, preventBack)
        handleClose()
        // window.history.go(1);

    }
    React.useEffect(() => {
        if (open) {
            // ブラウザバックを禁止する
            window.history.pushState(null, null, window.location.href);
            window.addEventListener('popstate', preventBack);
        } else {
            window.removeEventListener(`popstate`, preventBack)
        }

    })
    return (
        <div>

            {/* <Button
                sx={sx}
                variant='contained' color="primary" onClick={handleClickOpen}>
                プレビュー
            </Button> */}
            {renderInput({ onClick: () => handleClickOpen() })}

            {/* <Fab onClick={handleClickOpen}
                variant="extended" size="small"
                color="primary"
                sx={{ fontFamily: "inherit", my: 3 }}
                aria-label="show-feedback">
                <PrintIcon />
            </Fab> */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{`${item?.card_id?.front_side}`}</DialogTitle> */}
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {`${item?.front_side}`}
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Create ref={componentRef} id={item?.card_id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        キャンセル
                    </Button>

                </DialogActions>
            </Dialog>
        </div>)
}

export default React.memo(DialogButtonForCreate)