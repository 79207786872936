import defaultJson from './default.json'
export const loadSettings = () => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    // const cardFilter = Object.values(defaultJson.cardfilters)
    // const defalutSetting = JSON.stringify(defaultJson);
    return ({
        ...defaultJson, ...settings
    })
    // return ({
    //     ...defaultJson, ...settings
    // })
}