import { supabase } from "../supabaseClient";

// import { useAuth } from "../views/auth/AuthProvider";
// const User = () => {
//   const { user } = useAuth();
//   return user
// }
// export default User

// const dateTime = new Date().toISOString().toLocaleString("ja-JP");
const dateTime = new Date();

// export const sbFetch = async (table, select) => {
//   const { data, error } = await supabase.from(table).select(select);
//   if (error) {
//     console.log("sbFetch error ", error.message);
//     sbAppendErrorLog(table, error)
//     alert(error.message);
//     throw error;
//   }
//   return { data, error };
// };


export const sbUpsert = async (table, values, history_data = []) => {
  const { data, error } = await supabase.from(table)
    .upsert({
      ...values,
      updated_at: new Date(), //順番が大事。最後にしないとだめ。
    });
  if (error) {
    console.log("sbUpsert error", error.message);
    //sbAppendErrorLog(table, error)
    // alert(error.message);
  }
  if (data) {
    console.log(data);
    // sbAppendLog(table, [values, ...history_data]);
  }
  return { data, error };
};

export const earliestNextQuestion = async (table, user) => {
  const { data, error } = await supabase
    .from(table)
    .select("*")
    .eq("created_by", user.id)
    .order("next_question_date_time", { ascending: true })
    .limit(1)
    .single()
  if (error) {
    console.log("sbUpsert error", error.message);
  }
  if (data) {
    console.log(data);
  }
  return { data, error };
};






















export const sbUpdate = async (table, id, values, user_id = "") => {
  try {
    let { data, error } = await supabase
      .from(table)
      .update({
        ...values,
        updated_at: dateTime, //順番が大事。最後にしないとだめ。
      })
      .eq(`${table}_id`, id);
    if (error) {
      throw error;
    } else if (data) {
      console.log(data);
      return data[0];
    }
  } catch (error) {
    console.log("sbUpdate error", error.message);
    sbAppendErrorLog(table, error)
    alert(error.message);
    throw error;
  }
};





export const sbFetchWithFilter = async (
  table,
  filterColumn,
  filterValue,
  selectColumn,
  sortColumn = `${table}_id`,
  ascending = false
) => {
  try {
    let { data, error } = await supabase
      .from(table)
      .select(selectColumn)
      .eq(filterColumn, filterValue)
      .order(sortColumn, { ascending: ascending });
    if (error) {
      throw error;
    }
    return { data, error };
  } catch (error) {
    console.log("sbFetchWithFilter error ", error.message);
    sbAppendErrorLog(table, error)
    alert(error.message);
    throw error;
  }
};
export const sbFetchWithFilterSort = async (
  table,
  filterColumn,
  filterValue,
  selectColumn,
  sortColumn = `${table}_id`,
  ascending = false
) => {
  try {
    let { data, error } = await supabase
      .from(table)
      .select(selectColumn)
      .eq(filterColumn, filterValue)
      .order(sortColumn, { ascending: ascending });

    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.log("sbFetchWithFilterSort error ", error.message);
    sbAppendErrorLog(table, error)
    alert(error.message);
    throw error;
  }
};
export const sbRead = async (table, id, selectColumn = "*") => {
  const { data, error } = await supabase
    .from(table)
    .select(selectColumn)
    .eq(`${table}_id`, id)
    .single();
  if (error) {
    console.log("sbRead error", error.message);
    // sbAppendErrorLog(table, error)
    alert(`sbRead error! ${error.message}`);
    throw error;
  }
  return { data, error };
};


export const sbCreate = async (table, values) => {
  try {
    let { data, error } = await supabase.from(table).insert([
      {
        ...values,
        updated_at: dateTime, //クリエイトの場合のupdated_atはどこでも良い。
      },
    ]);
    if (error) {
      throw error;
      //throw error;
    } else if (data) {
      console.log(data);
      return data[0];
    }
  } catch (error) {
    console.log("sbCreate error", error.message);
    sbAppendErrorLog(table, error)
    // alert(error.message);
    throw error;
  }
};


export const sbDelete = async (table, id, history_data = []) => {
  try {
    const { data, error } = await supabase.from(table)
      .delete()
      .eq(`${table}_id`, id)
    if (error) {
      throw error;
      //throw error;
    } else if (data) {
      console.log(data);
      sbAppendLog(table, [id, ...history_data]);
      return data[0];
    }
  } catch (error) {
    console.log("sbDelete error", error.message);
    sbAppendErrorLog(table, error)
    alert(error.message);
    throw error;
  }
}

export const sbAppendLog = async (table, values) => {
  const { data, error } = await supabase.from("log").insert([
    {
      table: table,
      data: [...values],
      updated_at: dateTime, //順番が大事。最後にしないとだめ。
    },
  ]);
  if (error) {
    console.log("sbAppendLog error", error.message);
    sbAppendErrorLog(table, error)
    alert(error.message);
    throw error;
    //throw error;
  } else if (data) {
    console.log(data);
  }
  return { data, error };


};
export const sbAppendErrorLog = async (table, error_obj) => {
  try {
    let { data, error } = await supabase.from("error_log").insert([
      {
        table: table,
        error_message: error_obj.message,
        updated_at: dateTime, //順番が大事。最後にしないとだめ。
      },
    ]);
    if (error) {
      throw error;
      //throw error;
    } else if (data) {
      console.log(data);
      return data[0];
    }
  } catch (error) {
    console.log("sbAppendLog error", error.message);
    alert(error.message);
    throw error;
  }
};

export const sbGetList = async (bucket, path) => {
  const { data, error } = await supabase.storage.from(bucket).list(path);
  if (error) {
    console.log("Error sbGetList: ", error.message);
    //alert(error.message);
    sbAppendErrorLog(bucket, error)
    throw error;
  }
  return { data, error };

};

export const sbDownloadImage = async (bucket, path) => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .download(`/${path}`);
  if (error) {
    console.log("Error downloading image: ", error.message, path);
    // sbAppendErrorLog(path, error)
    throw error;
  }
  const url = URL.createObjectURL(data);
  return { url, error };

};

export const sbUploadImage = async (bucket, file, name) => {
  try {
    if (name.length === 0) {
      throw new Error("You must select an image to upload.");
    }
    console.log(file);
    let { error } = await supabase.storage
      .from(bucket)
      // .upload(name, file);
      .upload(name, file, {
        cacheControl: '3600',
        upsert: true,
      });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.log("Error upload image: ", error.message);
    alert(error.message);
  } finally {
  }
};


export const sbRemoveImage = async (bucket, name) => {
  try {
    if (name.length === 0) {
      throw new Error("You must select an image to upload.");
    }
    const { error } = await supabase.storage.from(bucket).remove([name]);
    if (error) {
      throw error;
    }
  } catch (error) {
    console.log("Error remove image: ", error.message);
    alert(error.message);
  } finally {
  }
};
