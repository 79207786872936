
export const ToastStyle = {
    success: {
        style: {
            borderRadius: '10px',
            background: '#fff',
            color: '#555',
        },
    },
    error: {
        style: {
            borderRadius: '10px',
            background: 'rgb(22 163 74)',
            color: '#fff',
        },
    },
}

