import React from 'react'
import TextCard from './TextCard';
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import Loading from '../../parts/Loading';
import { v4 as uuidv4 } from "uuid";
import { useAuth } from '../auth/AuthProvider';

import {
    Container,
    Box,
    Avatar,
    TablePagination,
    InputBase,
    Paper,
    InputAdornment,
    Autocomplete, createFilterOptions, Link, Typography, TextField, Divider, IconButton
} from '@mui/material';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { SpeakText } from '../../helper/SpeakText';
import { Copyright } from '../../parts/Copyright';
const Cards = () => {
    const [needFetch, setNeedFetch] = React.useState(false);
    const { user } = useAuth()
    const card_id = ""
    const [page, setPage] = React.useState(0)
    const [count, setCount] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState({ "back_side": "", "front_side": "", "correct_options": "" })
    SpeakText("", "en-US", null)

    const handleSearchClick = () => {

        setNeedFetch(true)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setNeedFetch(true)
        window.scrollTo(0, 0)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setNeedFetch(true)
        window.scrollTo(0, 0)
    };


    React.useEffect(() => {
        setNeedFetch(true)
    }, [])

    const fetchData = async (card_id) => {
        const { data, error } = await supabase
            .from("card")
            .select("*")
            .eq("created_by", user.id)
            .range(0 + rowsPerPage * page, rowsPerPage * page + rowsPerPage)
            .ilike('front_side', `%${search.front_side}%`)
            .ilike('back_side', `%${search.back_side}%`)
            .ilike('correct_options', `%${search.correct_options}%`)
            .order("updated_at", { ascending: false });
        const { count } = await supabase
            .from('card')
            .select('*', { count: 'exact', head: true })
            .eq("created_by", user.id)
            .ilike('front_side', `%${search.front_side}%`)
            .ilike('back_side', `%${search.back_side}%`)
            .ilike('correct_options', `%${search.correct_options}%`)
        // .match({ post_id: 2 })
        setCount(count)
        setNeedFetch(false)
        return { data, error };
    }

    const { isLoading, error, data } = useQuery(
        ["cards", card_id], () => fetchData(card_id),
        {
            enabled: needFetch
        }
    )

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (!data) { return <Loading />; }

    return (
        <Container component="main" maxWidth="xs">

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <ViewAgendaIcon />
                </Avatar>


                <Typography component="h1" variant="h5">
                    Cards
                    {console.log("work")}
                </Typography>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 380 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Front Side"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(res) => {
                            setSearch(search => ({ ...search, "front_side": res.target.value }))
                        }}
                        onKeyPress={e => {
                            // e.keyCodeは常に0になる
                            if (e.key === 'Enter') {
                                // エンターキー押下時の処理
                                e.preventDefault()
                                handleSearchClick()
                            }
                        }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                        onClick={handleSearchClick}
                    >
                        <SearchIcon />
                    </IconButton>

                </Paper>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 380 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Back Side"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(res) => {
                            setSearch(search => ({ ...search, "back_side": res.target.value }))
                        }}
                        onKeyPress={e => {
                            // e.keyCodeは常に0になる
                            if (e.key === 'Enter') {
                                // エンターキー押下時の処理
                                e.preventDefault()
                                handleSearchClick()
                            }
                        }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                        onClick={handleSearchClick}
                    >
                        <SearchIcon />
                    </IconButton>

                </Paper>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 380 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Correct"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(res) => {
                            setSearch(search => ({ ...search, "correct_options": res.target.value }))
                        }}
                        onKeyPress={e => {
                            // e.keyCodeは常に0になる
                            if (e.key === 'Enter') {
                                // エンターキー押下時の処理
                                e.preventDefault()
                                handleSearchClick()
                            }
                        }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                        onClick={handleSearchClick}
                    >
                        <SearchIcon />
                    </IconButton>

                </Paper>
                {data.data && data.data.map((item, idx) => (
                    <TextCard key={idx} item={item} />
                )
                )}
            </Box>
            <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
                    <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
                    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
                </BottomNavigation>
            </Paper> */}
            <Copyright />

        </Container>

    )
}

export default Cards