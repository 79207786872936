import React from 'react'
import {
    Container,
    Box,
    Avatar,
    Typography
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Agenda from './Agenda';
import { Copyright } from '../../parts/Copyright';


const Workout = () => {

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <FitnessCenterIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Workout
                </Typography>
                <Agenda onChange={(res) => {
                    // console.log(res)
                }} />
            </Box>
            <Copyright />
        </Container>

    )
}

export default Workout