import React from "react";
import ReactDOM from "react-dom";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox, ListItemButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//https://qiita.com/foooomio/items/1648e3a78ed2e40003f7


const SortableList = ({ item, onChangeTags, onChangeDeleteTags }) => {
    const [checked, setChecked] = React.useState([]);
    const [items, setItems] = React.useState(Object.values(item));
    // const [items, setItems] = React.useState([
    //     { id: "1", text: "Item 1" },
    //     { id: "2", text: "Item 2" },
    //     { id: "3", text: "Item 3" },
    //     { id: "4", text: "Item 4" }
    // ]);

    React.useEffect(() => {
        // setItems(Object.values(item))
        setItems(item)

    }, [item])


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        onChangeDeleteTags(newChecked)
    };

    const DragHandle = SortableHandle(() => (
        <ListItemIcon>
            <DragHandleIcon />
        </ListItemIcon>
    ));

    const SortableItem = SortableElement(({ text, key }) => (
        <ListItem key={key} disablePadding>
            <ListItemButton role={undefined} onClick={handleToggle(text)} dense>

                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked.indexOf(text) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': text }}
                    />
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>

            <ListItemSecondaryAction edge="end">
                <DragHandle />
            </ListItemSecondaryAction>
        </ListItem>

    ));

    const SortableListContainer = SortableContainer(({ items }) => (
        <List component="div"
            sx={{
                width: '100%', maxWidth: 360, overflow: 'auto',
                maxHeight: 200,
                '& ul': { padding: 0 }, bgcolor: 'background.paper'
            }}>
            {items.map(({ id, text }, index) => (
                <SortableItem key={index} index={index} text={text} />
            ))}
        </List>
    ));
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMoveImmutable(items, oldIndex, newIndex)
        // setItems(items => arrayMoveImmutable(items, oldIndex, newIndex));
        // onChange(arrayMoveImmutable(items, oldIndex, newIndex))
        setItems(newValue)
        onChangeTags(newValue)
    };

    return (
        <SortableListContainer
            items={items}
            onSortEnd={onSortEnd}
            useDragHandle={true}
            lockAxis="y"
        />
    );
};

export default React.memo(SortableList)