import React from 'react'
import { Toolbar, Typography, Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Container, Stack } from '@mui/material'
import { Link } from 'react-router-dom';

import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { supabase } from "../supabaseClient";
import { useAuth } from '../views/auth/AuthProvider';
import Workout from '../views/workout/Workout';

// const pages = ['workout', 'create', 'history'];
const pages = [
    { text: "workout", link: "workout" },
    { text: "dashboard", link: "dashboard" },
    { text: "New", link: "create/" },
    { text: "create_wrong", link: "create/front=front&back=back&correct=correct,correct2&wrong=wrong,wrong2&tags=a,b,c" },
    { text: "create_correct_tag", link: "create/front=front&correct=correct,correct2&tags=a,b,c" },
    // { text: "create_tags", link: "create/front=front&back=back&tags=a,札幌,Acctions" },
    // { text: "create_correct_wrong", link: "create/front=front&correct=correct,correct2&wrong=wrong,wrong2&tags=a,札幌,Acctions" },
    // { text: "create_f_b", link: "create/front=front&back=back" },
    // { text: "create_correct_wrong", link: "create/front/back/tag1,tag2/correct,correct2/wrong1,wrong2" },
    // { text: "create_f_b2", link: "create/front/back/back" },
    { text: "history", link: "history" },
    { text: "cards", link: "cards" },
    { text: "settings", link: "settings" },
    { text: "dictionary", link: "dictionary" }
]
const settings = ['Logout'];

const SignInLinks = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { user } = useAuth();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null);
        switch (event.currentTarget.textContent) {
            case 'workout':
                break
            case 'create':
                break
            case 'history':
                break
        }
    };

    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null);
        switch (event.currentTarget.textContent) {
            case 'Logout':
                supabase.auth.signOut()
                break
        }
    };

    return (
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block' },
                        }}
                    >
                        {/* {pages.map((page) => (
                            <MenuItem key={page} id={page} onClick={() => <Link to='/history' style={{ textDecoration: 'none' }} />}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))} */}
                        {pages.map((page) => (
                            <MenuItem key={page.text} component="a" href={`/${page.link}`}>{page.text}</MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MIU & AIRA
                    </Typography>

                </Box>


                <Box sx={{ flexGrow: 0 }}>
                    <Stack direction="row">

                        <Typography sx={{ m: 1 }}>
                            {user?.user_metadata.name}
                        </Typography>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src={user?.user_metadata.avatar_url} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>

                </Box>

            </Toolbar>
        </Container >


    )

}

export default SignInLinks