
export const arrayTagsToObject = (data) => {
    //tagsを連結して、arrayにして重複をカウントしてソートする。
    const tagList = data.map(x => x.tags).reduce((acc, cur) => acc + ', ' + cur)
    const tempTags = tagList.split(",").reduce(function (prev, current) {
        return addTag(prev, current)
    }, {});

    return sortTags(tempTags)
}

const addTag = (prev, current) => {
    if (current.trim() !== "null") {
        // prev[current.trim()] = (prev[current.trim()] || 0) + 1
        const count = (prev[current.trim()] || 0) && prev[current.trim()].count + 1
        prev[current.trim()] = { tagname: current.trim(), count: count }
        return prev
    } else {
        return prev
    }
}

const sortTags = (tempTags) => {
    //ソートする
    //①要素を変数保持
    const tags = Object.keys(tempTags).map(function (key) {
        return tempTags[key];
    }).sort(function (a, b) { //②dateでソート
        return (a.count > b.count) ? -1 : 1;  //オブジェクトの昇順ソート
    });
    return tags
}



export const addTagText = (currentTags, AddTags) => {
    const newTags = Object.values(currentTags).map((item, key) => (
        AddTags.indexOf(item.tagname) !== -1
            ? { tagname: item.tagname, count: item.count + 1 }
            : item
    ))

    return sortTags(newTags)
}