import React, { useCallback } from 'react'
import { useQuery } from "react-query";
import Loading from '../../parts/Loading';

import {
    Container, IconButton,
    Box,
    Avatar,
    List, ListItemButton, ListItemText, ListItemIcon, Collapse,
    Paper, BottomNavigation, BottomNavigationAction,
    Slider,
    Fab,
    Autocomplete, TextField
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { SpeakText } from "../../helper/SpeakText";


const SettingsVoices = ({ settings = { lang: "", voice: "" }, onChange }) => {
    const [voices, setVoices] = React.useState()//uniqueVoiceからユーザーが選択肢たLangによってフィルターされた値を表示するために使用する。
    const [needFetch, setNeedFetch] = React.useState(false);
    const [uniqueLangs, setUniqueLangs] = React.useState()
    const [uniqueVoices, setUniqueVoices] = React.useState()
    const [state, setState] = React.useState()
    SpeakText("", "en-US")

    React.useEffect(() => {
        setNeedFetch(true)
    }, [])
    //useQuery を使ったFetchの正解！！！！
    const fecthVoices = async (card_id) => {
        const data = await speechSynthesis.getVoices();
        if (data && data.length > 0) {
            setNeedFetch(false)
            return data;
        } else {
            return new error()
        }
    }
    const { isLoading, error, data } = useQuery(
        ["voices", "id"], () => fecthVoices("id"),
        {
            enabled: needFetch,
            retryDelay: 1000,
            onSuccess: (data) => {
                const languages =
                    data.map((value, key) => (
                        data[key].name !== "" &&
                        {
                            voice: data[key].name,
                            lang: data[key].lang,
                        }
                    ))
                setVoices(languages)
                setUniqueVoices(languages)
                setUniqueLangs(
                    languages.filter(
                        (element, index, self) => self.findIndex((e) => e.lang === element.lang) === index
                    )
                )
                setState(settings)
            }
        }
    )


    const filterVoicesHandler = (lang) => {
        const filterVoices = uniqueVoices.filter(
            (item) => item.lang === lang.lang
        );
        setVoices(filterVoices);
    };
    if (isLoading) return <Loading />;
    if (!state) return <Loading />;
    const handleChange = (newValue) => {
        const newState = { ...state, lang: newValue.lang, voice: newValue.voice }
        // setState((item) => ({ ...item, lang: newValue.lang, voice: newValue.voice }))
        setState(newState)
        onChange(newState)
    }
    const handleChangeRate = (newValue) => {
        const newState = { ...state, "rate": newValue }
        setState(newState)
        onChange(newState)
    }
    return (
        <>
            <Toaster />
            <Autocomplete
                sx={{ mt: 1 }}
                disablePortal
                fullWidth
                id="lang"
                value={state}
                // options={uniqueLangs.map((item) => item.lang)}
                options={uniqueLangs}
                getOptionLabel={(option) => option?.lang?.toString() || ""}
                isOptionEqualToValue={(option, value) => option.lang === value.lang}
                // getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                    filterVoicesHandler(newValue); // そのカテゴリーでフィルター
                    handleChange(newValue)
                }}
                renderInput={(params) => <TextField {...params} label="Lang" />}
            />
            <Autocomplete
                sx={{ mt: 1 }}
                disablePortal
                fullWidth
                id="voice"
                value={state}
                options={voices}
                getOptionLabel={(option) => option?.voice?.toString() || ""}
                isOptionEqualToValue={(option, value) => option.voice === value.voice}
                // sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Voices" />}
                onChange={(event, newValue) => {
                    // filterVoicesHandler(newValue); // そのカテゴリーでフィルター
                    // setState((item) => ({ ...item, lang: newValue.lang, voice: newValue.voice }))
                    handleChange(newValue)

                }}
            />
            <Slider defaultValue={1} step={0.1} marks min={0.1} max={3}
                valueLabelDisplay="auto"
                value={state?.rate || 1}
                onChange={(event, newValue) => (
                    // setState((state) => ({ ...state, "rate": newValue }))
                    handleChangeRate(newValue)
                )}
            />

            {/* <Fab color="primary" aria-label="add"
                sx={{ mt: 1 }}
                size="small"
                onClick={() => SpeakText(`Hello, I'm ${state.voice}. Nice to meet you `, state.lang, state.voice, state.rate)}>
                <VolumeUpIcon />
            </Fab> */}
            <IconButton color="primary" aria-label="add"
                sx={{ mt: 1 }}
                // size="small"
                onClick={() => SpeakText(`Hello, I'm ${state.voice}. Nice to meet you `, state.lang, state.voice, state.rate)}>
                <VolumeUpIcon />
            </IconButton>



        </>
    )
}

export default React.memo(SettingsVoices)