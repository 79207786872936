import React from "react";
import PropTypes from 'prop-types';

import { CssBaseline, Slide, useScrollTrigger, Toolbar, AppBar } from "@mui/material";

// import Header from "./Header";
import Content from "./Content";
import { AuthProvider, useAuth } from "../views/auth/AuthProvider";
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,
	Outlet,
} from "react-router-dom";
import Login from '../views/auth/Login'
import Dashboard from "../views/dashboard/Dashboard";
import SignInLinks from "./SignInLinks";
import SignOutLinks from "./SignOutLinks";
import Workout from "../views/workout/Workout";
import History from "../views/history/History";
import Create from "../views/create/Create";
import Cards from "../views/cards/Cards";
import Benche from "../views/workout/Benche";
import Settings from "../views/settings/Settings";
import Dictionary from "../views/dictionary/Dictionary";
import DictionaryToCard from "../views/dictionary/DictionaryToCard";
import HistoryDay from "../views/history/HistoryDay";

function PrivateOutlet() {
	const auth = useAuth();
	return auth.user ? <Outlet /> : <Navigate to="/login" />;
}
function PrivateHeaderOutlet() {
	const auth = useAuth();
	return auth.user ? <Outlet /> : <Navigate to="/login" />;
}
function Header() {
	const { user } = useAuth();
	return user ? <SignInLinks /> : <SignOutLinks />
}
function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

const ScrollToTop = () => {
	const { pathname } = React.useLocation()

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}


const Layout = (props) => {

	return (
		<AuthProvider>
			<CssBaseline />
			<HideOnScroll {...props}>
				<AppBar>
					<Header />
				</AppBar>
			</HideOnScroll>
			<Toolbar />
			<BrowserRouter>
				<Routes>
					<Route path="login" element={<Login />} />

					<Route path="/" element={<PrivateOutlet />}>
						<Route index element={<Workout />} />
					</Route>

					<Route path="workout" element={<PrivateOutlet />}>
						<Route index element={<Workout />} />
						<Route path="benche" element={<Benche />} />
					</Route>

					<Route path="dashboard" element={<PrivateOutlet />}>
						<Route index element={<Dashboard />} />
					</Route>

					{/* <Route path="create" element={<PrivateOutlet />}>
						<Route path=":front?/:back?/:tags?/:correct?/:wrong?" element={<Create />} />
					</Route> */}
					<Route path="create" element={<PrivateOutlet />}>
						<Route path="front=:front&back=:back&correct=:correct&wrong=:wrong&tags=:tags" element={<Create />} />
						<Route path="front=:front&back=:back&correct=:correct&wrong=:wrong" element={<Create />} />
						<Route path="front=:front&correct=:correct&wrong=:wrong&tags=:tags" element={<Create />} />
						<Route path="front=:front&correct=:correct&wrong=:wrong" element={<Create />} />
						<Route path="front=:front&correct=:correct&tags=:tags" element={<Create />} />
						<Route path="front=:front&back=:back&tags=:tags" element={<Create />} />
						<Route path="front=:front&back=:back" element={<Create />} />
						<Route path="id=:id" element={<Create />} />
						<Route path="" element={<Create />} />
					</Route>
					<Route path="card" element={<PrivateOutlet />}>
						<Route path="id=:id" element={<Create />} />
					</Route>
					<Route path="/cards/" element={<PrivateOutlet />}>
						{/* <Route path="id=:id" element={<Cards />} /> */}
						<Route path="" element={<Cards />} />
					</Route>

					<Route path="settings" element={<PrivateOutlet />}>
						<Route path="" element={<Settings />} />
					</Route>
					<Route path="dictionary" element={<PrivateOutlet />}>
						<Route index element={<Dictionary />} />
						<Route path="card" element={<DictionaryToCard />} />
					</Route>
					<Route path="/history" element={<PrivateOutlet />}>
						<Route path="date=:date" element={<HistoryDay />} />
						<Route index element={<History />} />
					</Route>

					{/* <Route path="/create/front=:front&back=:back&tags=:tags" element={<PrivateOutlet />}>
							<Route index element={<Create />} />
						</Route>
						<Route path="/create/front=:front&back=:back&tags=:tags&wrong=:wrong" element={<PrivateOutlet />}>
							<Route index element={<Create />} />
						</Route> */}

					{/* <Content /> */}
				</Routes>
			</BrowserRouter>
		</AuthProvider >

	)

};

export default Layout;
