import React, { useCallback } from 'react'
import { useQuery } from "react-query";
import Loading from '../../parts/Loading';

import {
    Button, Container,
    Box,
    Avatar,
    List, ListItemButton, ListItemText, ListItemIcon, Collapse,
    Paper, BottomNavigation, BottomNavigationAction,
    FormControl, InputLabel, Select, MenuItem,
    Typography,
    Autocomplete, TextField
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'
import SettingsVoices from './SettingsVoices';
import AllTags from './AllTags';
import { loadSettings } from './LocalSettings'
import { Copyright } from '../../parts/Copyright';

import ConfirmDialog from "../../parts/ConfirmDialog";

import InformationMUIRenderInput from '../../parts/InformationMUIRenderInput';
const Settings = () => {
    // localStorage.setItem('settings', JSON.stringify({ speech: { lang: 'en-US', voice: 'Albert' } }));

    // const settings = JSON.parse(localStorage.getItem('settings'));
    const [state, setState] = React.useState(loadSettings())
    const popupConfirmRef = React.useRef();

    // TODO 音声ファイルの選択
    // TODO 音声ファイルのシングルトン化
    // TODO interval level の変更
    const handleChange = (newValue) => {
        // const newState = { ...state, lang: newValue.lang, voice: newValue.voice }
        // // setState((item) => ({ ...item, lang: newValue.lang, voice: newValue.voice }))
        // setState(newState)
        // onChange(newState)
    }
    const handleSubmit = async (event, newValue) => {
        event.preventDefault();
        const res = await popupConfirmRef.current.confirm();
        if (!res) { return; }
        localStorage.setItem('settings', JSON.stringify(state));
    }
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                component="form"
                onSubmit={handleSubmit}
            >
                <Avatar sx={{ bgcolor: 'secondary.main' }}>
                    <SettingsIcon />
                </Avatar>
                <SettingsVoices settings={state.speech}
                    onChange={(res) =>
                        setState((state) => ({ ...state, "speech": { ...res } }))
                    }
                />
                <AllTags settings={state.cardfilters}
                    onChange={(cardfilters, topTags) =>
                        setState((state) => ({ ...state, "cardfilters": [...cardfilters], "toptags": [...topTags] }))
                    }
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>

            </Box>
            <Copyright />
            <ConfirmDialog message={"保存しますか？"} ref={popupConfirmRef} />

        </Container>

    )
}

export default Settings