import React from 'react'
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import { useAuth } from '../auth/AuthProvider';
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'
import Loading from '../../parts/Loading';
import { arrayTagsToObject } from '../../helper/TagUtil';
import SortableList from '../../parts/SortableList';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    List, ListItem, IconButton, ListItemButton, ListItemIcon, Checkbox, Button, Autocomplete, TextField, Stack
} from '@mui/material';
// import { Retryer } from 'react-query/types/core/retryer';
// TODO 'updated_at': ((new Date()).toISOString()).toLocaleString('zh-TW')
//https://keizokuma.com/js-array-object-sort/
const sortArray = (arr) => {
    if (typeof arr === 'undefined') { return arr }
    if (Object.keys(arr).length <= 1) { return arr }
    let result = arr.sort(function (a, b) {
        return (a.count < b.count) ? -1 : 1;  //オブジェクトの昇順ソート
    });
    return result
}

const getUniqueStr = (myStrong) => {
    var strong = 1000;
    if (myStrong) strong = myStrong;
    return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16)
}

const AllTags = ({ settings = [{ id: 1, tags: "" }], onChange }) => {
    const { user } = useAuth()
    const [needFetch, setNeedFetch] = React.useState(false);
    const maxRows = 1000
    const [state, setState] = React.useState()
    const [tags, setTags] = React.useState([])
    const [deleteTags, setDeleteTags] = React.useState()

    React.useEffect(() => {
        setNeedFetch(true)
        setState(settings)
    }, [settings])
    //useQuery を使ったFetchの正解！！！！
    const fetchData = async (id) => {
        // const now = new Date();
        const { data, error } = await supabase
            .from("card")
            .select("tags")
            .eq("created_by", user.id)
            .order('created_at', { ascending: false })
            // .lt('next_question_date_time', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`)
            .limit(maxRows)

        if (error) {
            toast.error(error.message, ToastStyle.error)
            return error;
        }
        return arrayTagsToObject(data)

    }
    const { isLoading, error, data: TopTags } = useQuery(
        ["AllTags", user.id], () => fetchData(user.id),
        { enabled: needFetch }
    )
    //

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (!TopTags) { return <Loading />; }

    const handleAddClick = () => {
        if (tags.length === 0) return
        const tagList = tags.map(x => x.tagname).reduce((acc, cur) => acc + ', ' + cur)
        const newValue = [...state, { "id": getUniqueStr, "text": tagList }]
        // setState((state) =>
        //     ([...state, { "id": state?.length + 1, "text": tagList }]))
        setState(newValue)
        onChange(newValue, TopTags)
    }
    const handleChage = (value) => {
        setState(value)
        onChange(value, TopTags)
    }
    const handleDelete = () => {
        const newState = state.filter((item) => {
            return deleteTags.indexOf(item.text) === -1 ? true : false
        })
        console.log(newState)
        setState(newState)
        onChange(newState, TopTags)

    }
    return (
        <>
            <Autocomplete
                multiple
                fullWidth
                id="tags-standard"
                options={TopTags}
                getOptionLabel={(option) => option.tagname}
                // defaultValue={[data[0]]}
                onChange={(event, newValue) => {
                    setTags(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tagを選択"
                        placeholder="Tag"
                    />
                )}
            />
            <Stack direction="row" spacing={2}>
                <Button aria-label="Example" color='primary'
                    onClick={() => handleDelete()}

                >
                    <KeyboardArrowUpIcon />
                </Button>
                <IconButton aria-label="Example" color='primary'
                    onClick={() => handleAddClick()}
                >
                    <KeyboardArrowDownIcon />
                </IconButton>

            </Stack>

            <SortableList item={state}
                onChangeTags={(res) => {
                    console.log(res)
                    handleChage(res)
                }}
                onChangeDeleteTags={(res) => {
                    console.log(res)
                    setDeleteTags(res)
                }}
            />




            {/* 
            <List sx={{
                width: '100%', maxWidth: 360, overflow: 'auto',
                maxHeight: 200,
                '& ul': { padding: 0 }, bgcolor: 'background.paper'
            }}>
                {state.map((value) => {
                    const labelId = `checkbox-list-label-${value.tagname}`;

                    return (
                        <>
                            <ListItem
                                key={value.text}
                                // secondaryAction={
                                //     <IconButton edge="end" aria-label="comments">
                                //         <CommentIcon />
                                //     </IconButton>
                                // }
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${value.text}`} />
                                </ListItemButton>
                            </ListItem>
                        </>

                    );
                })}
            </List> */}
        </>

    )

    // return (
    //     <List sx={{
    //         width: '100%', maxWidth: 360, overflow: 'auto',
    //         maxHeight: 200,
    //         '& ul': { padding: 0 }, bgcolor: 'background.paper'
    //     }}>
    //         {data.map((value) => {
    //             const labelId = `checkbox-list-label-${value.tagname}`;

    //             return (
    //                 <>
    //                     <ListItem
    //                         key={value.tagname}
    //                         // secondaryAction={
    //                         //     <IconButton edge="end" aria-label="comments">
    //                         //         <CommentIcon />
    //                         //     </IconButton>
    //                         // }
    //                         disablePadding
    //                     >
    //                         <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
    //                             <ListItemIcon>
    //                                 <Checkbox
    //                                     edge="start"
    //                                     checked={checked.indexOf(value) !== -1}
    //                                     tabIndex={-1}
    //                                     disableRipple
    //                                     inputProps={{ 'aria-labelledby': labelId }}
    //                                 />
    //                             </ListItemIcon>
    //                             <ListItemText id={labelId} primary={`${value.tagname}`} />
    //                         </ListItemButton>
    //                     </ListItem>
    //                 </>

    //             );
    //         })}
    //     </List>
    // )
}

export default AllTags