import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Chip, IconButton, TextField } from '@mui/material'
import { styled } from '@mui/material/styles';
// import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import AppsIcon from '@mui/icons-material/Apps';
import SubjectIcon from '@mui/icons-material/Subject';

const useStyles = styled(theme => ({
    // chip: {
    //     margin: theme.spacing(0.5, 0.25)
    // }
}));

export default function TagsInput({ ...props }) {
    // const classes = useStyles();
    const { onChange, placeholder, tags, ...other } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState([]);

    const [composing, setComposition] = React.useState(false);
    const startComposition = () => setComposition(true);
    const endComposition = () => setComposition(false);
    const [display, setDisplay] = React.useState({ "textInput": "none", "tagInput": "block" })


    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);
    useEffect(() => {
        if (selectedItem > 0) {
            onChange(selectedItem);
        }
    }, [selectedItem, onChange, tags]);

    function handleKeyDown(event) {
        if (event.key === "Enter" || event.type === "blur") {
            if (composing) return;
            event.preventDefault();
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            newSelectedItem.push(event.target.value.trim());
            setSelectedItem(newSelectedItem);
            setInputValue("");
            onChange(newSelectedItem);
        }
        if (
            // TODOこの辺修正しないとまずい。
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            const newValue = selectedItem.slice(0, selectedItem.length - 1)
            setSelectedItem(newValue);
            onChange(newValue);

        }
    }
    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
        onChange(newSelectedItem);

    }

    const handleDelete = item => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
        onChange(newSelectedItem);
    };

    function handleInputChange(event) {
        setInputValue(event.target.value);
        // onChange(event.target.value);

    }


    const handleTextModeChange = () => {
        if (display.textInput === "none") {
            setDisplay({ "textInput": "block", "tagInput": "none" })
        } else {
            setDisplay({ "textInput": "none", "tagInput": "block" })
        }
    }


    return (
        <React.Fragment >
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });
                    return (
                        <div style={{ "width": "100%" }}>
                            {/* <div > */}
                            <TextField
                                sx={{ display: { xs: `${display.tagInput}` } }}
                                InputProps={{
                                    startAdornment:
                                        <>
                                            <IconButton color="primary" onClick={event => {
                                                handleTextModeChange(event)
                                                handleInputChange(event)
                                            }
                                            } >
                                                <AppsIcon />
                                            </IconButton>
                                            {/* <Box sx={{ width: "6000", flexWrap: 'wrap' }}> */}
                                            {
                                                selectedItem.map(item => (
                                                    <Chip
                                                        key={item}
                                                        tabIndex={-1}
                                                        label={item}
                                                        sx={{ m: 0.5 }}
                                                        // className={classes.chip}
                                                        onDelete={handleDelete(item)}
                                                    />

                                                ))
                                            }
                                            {/* </Box> */}
                                        </>

                                    ,
                                    // endAdornment:
                                    //     <IconButton color="primary" onClick={handleTextModeChange} >
                                    //         <SubjectIcon />
                                    //     </IconButton>
                                    // ,
                                    onBlur: event => handleKeyDown(event)
                                    //  document.dispatchEvent(new KeyboardEvent("keydown", {key: "Enter" }))
                                    ,
                                    onChange: event => {
                                        handleInputChange(event);
                                    },
                                    onFocus
                                }}
                                // sx={{ widh: "100%" }}
                                onCompositionStart={startComposition}
                                onCompositionEnd={endComposition}
                                // fullWidth
                                // multiline
                                {...other}
                                {...inputProps}
                            />
                            <TextField
                                sx={{ display: { xs: `${display.textInput}` } }}
                                InputProps={{
                                    startAdornment:
                                        <IconButton color="primary" onClick={event => {
                                            handleTextModeChange(event)
                                            handleInputChange(event)
                                        }}>
                                            <SubjectIcon />
                                        </IconButton>
                                }}
                                fullWidth
                                {...other}
                                // multiline
                                value={selectedItem.join(",")}
                                onChange={(event) => {
                                    setSelectedItem(event.target.value.split(","))
                                }}
                            />
                        </div>
                    );
                }}
            </Downshift>

        </React.Fragment >
    );
}
TagsInput.defaultProps = {
    tags: []
};
TagsInput.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
};
