import React from "react";
// material-ui
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Container,
    Button,
    Collapse,
    Rating,
    Chip,
    Stack,
    IconButton,
    CardActions,
} from "@mui/material";
import { SpeakText } from "../../helper/SpeakText";
import { loadSettings } from '../settings/LocalSettings';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';

import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import Markdown from "../../parts/Markdown";
import DialogButtonForCreate from "../create/DialogButtonForCreate";

const TextCard = ({ item }) => {

    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const [settings, setSettings] = React.useState(loadSettings())

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container key={item.card_id} >
            <Card sx={{ mt: 1, maxWidth: "xs" }}

                onClick={() => SpeakText(item.front_side || "", settings.speech.lang, settings.speech.voice, settings.speech.rate)}>
                <CardHeader
                    title={
                        <>

                            <Markdown children={item?.front_side || ""} />

                        </>
                        // <Typography variant="h5">
                        //     {item?.front_side || ""}
                        // </Typography>
                    }
                    subheader={
                        <Stack sx={{ mt: 2 }} direction="row" spacing={1} justifyContent="center">
                            {(item?.tags || "").split(",").map((value, idx) => (
                                (value !== "")
                                &&
                                (< Chip key={idx} label={value} variant="outlined" size="small" />)
                            ))}
                        </Stack>
                    }

                />

                {/* <Divider /> */}
                <CardActions disableSpacing>
                    {/* <IconButton aria-label="PlayCircleIcon" onClick={() => SpeakText(item.front_side, "en-US", null)}>
                        <PlayCircleIcon />
                    </IconButton> */}
                    {/* <IconButton aria-label="share"
                        onClick={() => navigate(`/card/id=${item.card_id}`, { state: { id: 1 } })}>
                        <ShareIcon />
                    </IconButton> */}
                    <IconButton aria-label="share">
                        <DialogButtonForCreate
                            item={item}
                            renderInput={(params) => (
                                <CreateIcon {...params} />
                            )} />
                    </IconButton>

                    <div style={{ flexGrow: 1 }}></div>

                    <IconButton aria-label="expanded" onClick={handleExpandClick}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>

                    {/* <Button
                        fullWidth
                        onClick={handleExpandClick}
                        startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    /> */}
                    {/* {expanded ? "LESS" : "MORE"}
                    </Button> */}
                </CardActions>
                <CardContent >
                    <Collapse in={!expanded} timeout="auto" unmountOnExit>
                        <Rating name="read-only" value={item?.question_interval * 5 / 16381233 || 0} precision={0.1} readOnly />
                    </Collapse>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Stack direction="row" spacing={1} justifyContent="center">
                            {(item?.correct_options || '').split(",").map((value, idx) => (
                                (value !== "") &&
                                <Chip key={idx} label={value} variant="outlined" />
                            ))}
                        </Stack>
                        {/* <Typography sx={{ mt: 1 }} variant="body1">
                            {item.back_side}
                        </Typography> */}
                        <Markdown children={item?.back_side || ""} />

                    </Collapse>
                </CardContent>
                <div>

                </div>
            </Card>
        </Container >
    );
};

export default React.memo(TextCard);