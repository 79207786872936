import React from 'react'
import {
    Link, Typography
} from '@mui/material';
export const Copyright = (props) => {
    return (
        <Typography sx={{ mt: 8, mb: 4 }} variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
                miu & aira & papa
            </Link>{' '}
            {/* utc時刻の年じゃなくてOK */}
            {new Date().getFullYear()}
            {'.'}
        </Typography>

    );
}