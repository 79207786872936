import React from 'react'
import {
    Container,
    Box,
    Avatar,
    List, ListItem, ListItemButton, ListItemText,
    Typography,
    ListSubheader,
    IconButton,
    Pagination,
    TextField
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import { useAuth } from '../auth/AuthProvider';
import Loading from '../../parts/Loading';
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'
import { useNavigate } from "react-router-dom"
import { Copyright } from '../../parts/Copyright';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';


import Graph from './Graph';

function formatDate(dt) {
    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth() + 1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    return (y + '-' + m + '-' + d);
}

const History = () => {
    const { user } = useAuth()
    const [needFetch, setNeedFetch] = React.useState(false);
    const card_id = ""
    const navigate = useNavigate()
    const [value, setValue] = React.useState(dayjs());
    const startDate = value.date() >= 20 ? value.date(20) : value.date(20).subtract(1, 'month');
    const endDate = startDate.add(1, 'month').date(19).endOf('day');
    const daysDiff = endDate.diff(startDate, 'days') + 1;

    React.useEffect(() => {
        setNeedFetch(true)
    }, [value])

    //useQuery を使ったFetchの正解！！！！
    const fetchData = async (card_id) => {


        const { data, error } = await supabase
            .from("total_by_day")
            .select("*")
            .eq("created_by", user.id)
            //getUTCMonth()は0始まり
            // .gte('grouping_created_at', `${now.getUTCFullYear()}-${now.getUTCMonth() + 0}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`)
            // .lte('grouping_created_at', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`)
            // .gte('grouping_created_at', `${value.format('YYYY')}-${value.format('M')}-1 00:00:00`)
            // .lte('grouping_created_at', `${value.format('YYYY')}-${value.format('M')}-${value.endOf("month").format("DD")} 00:00:00`)
            .gte('grouping_created_at', startDate.format('YYYY-MM-DD HH:mm:ss'))
            .lte('grouping_created_at', endDate.format('YYYY-MM-DD HH:mm:ss'))
            .order("grouping_created_at", { ascending: false })

        if (error) {
            toast.error(error.message, ToastStyle.error)
            return error;
        } else if (data) {
            console.log(data);
            setNeedFetch(false)
            const dateData = data.map((item) => (
                {
                    grouping_created_at: new Date(item.grouping_created_at).getTime(),
                    created_by: item.created_by,
                    result_true: item.result_true,
                    result_false: item.result_false
                }
            ))
            return dateData;
        }
    }
    const { isLoading, error, data } = useQuery(
        ["Quiz", card_id], () => fetchData(card_id),
        { enabled: needFetch }
    )
    //
    const handleOnClick = (date) => {
        console.log(date)
        navigate(`/history/date=${date}`, { state: { id: 1 } })

    }
    const handleClickXAxis = (event) => {
        handleOnClick(formatDate(new Date(event.activeLabel)))
    };

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (!data) { return <Loading />; }


    const totalResultTrue = data.reduce((sum, item) => sum + item.result_true, 0);



    return (
        <Container component="main" maxWidth="xs">
            <Toaster />
            <Box sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <IconButton color="secondary"
                    onClick={() => {
                        setValue(value.add(-1, 'M'));
                    }}
                >
                    <NavigateBeforeIcon />
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={'"month" and "year"'}
                        views={['month', 'year']}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </LocalizationProvider>
                <IconButton color="secondary"
                    onClick={() => {
                        setValue(value.add(1, 'M'));
                    }}
                >
                    <NavigateNextIcon />
                </IconButton>
            </Box>

            {totalResultTrue}
            {"/"}
            {daysDiff * 100}

            <BarChart
                width={330}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
                onClick={handleClickXAxis}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="grouping_created_at"
                    domain={['auto', 'auto']}
                    tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                    type='number'

                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="result_true" stackId="a" fill="#8884d8" name="正解" />
                <Bar dataKey="result_false" stackId="a" fill="#82ca9d" name="不正解" />
            </BarChart>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="secondary mailbox folders">
                    <List
                        subheader={<ListSubheader>日付　　　　　　　　　　　　　　正解　　不正解</ListSubheader>}>
                        {data && data.map((item, key) => (
                            <ListItem disablePadding key={key}>
                                <ListItemButton onClick={() => handleOnClick(formatDate(new Date(item.grouping_created_at)))}>
                                    <ListItemText
                                        primary={
                                            formatDate(new Date(item.grouping_created_at))
                                        }

                                    />
                                    <ListItemText
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                                        primary={`${item.result_true}　${item.result_false}`} justify="flex-end" direction="row" />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </nav>
            </Box>
            <Copyright />
        </Container>
    )

}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p>{`${new Date(label).toLocaleDateString()}`}</p>
                <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
                <p className="label">{`${payload[1].name} : ${payload[1].value}`}</p>
                {/* <p className="intro">{getIntroOfPage(label)}</p> */}
            </div>
        );
    }

    return null;
};

export default History