import React from 'react'
import {
    Container, IconButton,
    Box,
    Avatar,
    List, ListItemButton, ListItemText, ListItemIcon, Collapse,
    Paper, BottomNavigation, BottomNavigationAction,
    Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'

import HistoryIcon from '@mui/icons-material/History';
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import { useAuth } from '../auth/AuthProvider';
import Loading from '../../parts/Loading';

import { useNavigate } from "react-router-dom"
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DialogButtonForCreate from '../create/DialogButtonForCreate';
import Markdown from '../../parts/Markdown';
import { Copyright } from '../../parts/Copyright';

const HistoryDay = () => {
    const { date } = useParams();
    const navigate = useNavigate()
    const { user } = useAuth()
    const [needFetch, setNeedFetch] = React.useState(false)
    // const [cards, setCards] = React.useEffect(() => refreshCards())
    const [open, setOpen] = React.useState({})
    const [cardFilter, setCardFilter] = React.useState(0);
    const filterBool = { 0: true, 1: false }
    const card_id = ""
    React.useEffect(() => {
        setNeedFetch(true)
    }, [])


    //useQuery を使ったFetchの正解！！！！
    const fetchData = async (card_id) => {
        const ltDate = new Date(date)
        ltDate.setDate(ltDate.getDate() + 1);
        const { data, error } = await supabase
            .from("total_by_day_2")
            .select("*,card_id(*)")
            .gte("grouping_updated_at", date)
            .lt("grouping_updated_at", ltDate.toISOString())
            .order("grouping_updated_at", { ascending: false })
            .eq("created_by", user.id)
        if (error) {
            toast.error(error.message, ToastStyle.error)
            return error;
        } else if (data) {
            console.log(data);
            setNeedFetch(false)
            return data;
        }
    }
    const { isLoading, error, data } = useQuery(
        ["Quiz", card_id], () => fetchData(card_id),
        {
            enabled: needFetch,
            // onSuccess: (data) => setCards(data)
        }
    )
    //
    const handleOnClick = (card) => {
        console.log(card)
        navigate(`/card/id=${card.card_id || ""}`, { state: { id: 1 } })

    }
    const handleClick = (key) => {
        const bool = (open[key]) ? !open[key] : true
        setOpen((open) => ({ ...open, [key]: bool }));
        // setState((state) => ({ ...state, [e.target.id]: e.target.value }));

    };

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (!data) { return <Loading />; }


    return (
        <Container component="main" maxWidth="xs">
            <Toaster />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <HistoryIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {date}
                </Typography>

                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            {data && data.filter((item) => {
                                return item.result === filterBool[cardFilter]
                            }).map((item, key) => (
                                // <ListItem disablePadding key={key}>
                                //     <ListItemIcon>
                                //         {item?.result ? <ThumbUpOffAltIcon /> : <DangerousIcon />}
                                //     </ListItemIcon>
                                //     <ListItemButton onClick={() => handleOnClick(item?.card_id || "")}>
                                //         <ListItemText primary={`${item?.card_id?.front_side || ""} ${item?.selected || ""}`} secondary={new Date(item?.updated_at).toLocaleTimeString() || ""} />
                                //     </ListItemButton>
                                //     <ListItemButton onClick={() => handleOnClick(item?.card_id || "")}>
                                //         <ThumbUpOffAltIcon />
                                //     </ListItemButton>
                                // </ListItem>
                                // <ListItem
                                //     key={key}
                                //     secondaryAction={
                                //         <IconButton edge="end" aria-label="comments"
                                //             onClick={() => handleOnClick(item?.card_id || "")}>
                                //             <MoreVertIcon />
                                //         </IconButton>
                                //     }
                                //     disablePadding
                                // >
                                //     <ListItemButton role={undefined} dense onClick={() => handleClick(key)}>
                                //         <ListItemIcon id={key} onClick={() => handleOnClick(item?.card_id || "")}>
                                //             {item?.result ? <ThumbUpOffAltIcon /> : <DangerousIcon />}
                                //         </ListItemIcon>
                                //         <ListItemText id={key} primary={`${item?.card_id?.front_side || ""} ${item?.selected || ""}`} secondary={new Date(item?.updated_at).toLocaleTimeString() || ""} />
                                //         {open[key] ? <ExpandLess /> : <ExpandMore />}
                                //     </ListItemButton>
                                //     <Collapse in={open[key]} timeout="auto" unmountOnExit>
                                //         <List component="div" disablePadding>
                                //             <ListItemButton sx={{ pl: 4 }}>
                                //                 <ListItemIcon>
                                //                     <ExpandLess />
                                //                 </ListItemIcon>
                                //                 <ListItemText primary="Starred" />
                                //                 <ListItemText primary="Starred" />

                                //                 <ListItemText primary="Starred" />
                                //                 <ListItemText primary="Starred" />

                                //             </ListItemButton>
                                //         </List>
                                //     </Collapse>
                                // </ListItem>
                                <List>
                                    <ListItemButton role={undefined} dense onClick={() => handleClick(key)}>
                                        {/* <ListItemIcon id={key} onClick={() => handleOnClick(item?.card_id || "")}>
                                            {item?.result ? <ThumbUpOffAltIcon /> : <DangerousIcon />}
                                        </ListItemIcon> */}
                                        <ListItemIcon id={key}>
                                            {/* {item?.result ? <ThumbUpOffAltIcon /> : <DangerousIcon />} */}
                                            {item?.result ?
                                                <DialogButtonForCreate
                                                    item={item?.card_id}
                                                    renderInput={(params) => (
                                                        <ThumbUpOffAltIcon {...params} />
                                                    )} />
                                                :
                                                <DialogButtonForCreate
                                                    item={item?.card_id}
                                                    renderInput={(params) => (
                                                        <DangerousIcon {...params} />
                                                    )} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText
                                            id={key}
                                            primary={
                                                <>
                                                    < Markdown children={`${item?.card_id?.front_side || ""}`} />
                                                    {/* {item?.selected || ""} */}
                                                    {item?.count || ""}

                                                </>

                                            }
                                            secondary={new Date(item?.updated_at).toLocaleTimeString() || ""}
                                        />
                                        {open[key] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open[key]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item?.card_id?.history && Object.entries(item?.card_id?.history).map((value, key) => (
                                                <ListItemButton sx={{ pl: 4 }} key={key}>
                                                    {/* <ListItemIcon id={key} onClick={() => handleOnClick(item?.card_id || "")}> */}
                                                    <ListItemIcon id={key} >
                                                        {value[1].result ?
                                                            <DialogButtonForCreate
                                                                item={item?.card_id}
                                                                renderInput={(params) => (
                                                                    <ThumbUpOffAltIcon {...params} />
                                                                )} />
                                                            :
                                                            <DialogButtonForCreate
                                                                item={item?.card_id}
                                                                renderInput={(params) => (
                                                                    <DangerousIcon {...params} />
                                                                )} />
                                                        }
                                                    </ListItemIcon>
                                                    {/* <ListItemButton sx={{ pl: 4 }}> */}
                                                    <ListItemText primary={`${[value[1].selectValue] || ""}`} secondary={new Date(value[0]).toLocaleDateString() || ""} />
                                                    {/* </ListItemButton> */}
                                                </ListItemButton>

                                            ))}

                                        </List>
                                    </Collapse>
                                </List>

                            ))}
                        </List>
                    </nav>
                </Box>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                    <BottomNavigation
                        showLabels
                        value={cardFilter}
                        onChange={(event, newValue) => {
                            setCardFilter(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Correct" icon={<ThumbUpOffAltIcon />} />
                        <BottomNavigationAction label="Wrong" icon={<DangerousIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
            <Copyright />
        </Container>)
}

export default React.memo(HistoryDay)