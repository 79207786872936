import React from 'react'
import { v4 as uuidv4 } from "uuid";
import { useAuth } from '../auth/AuthProvider';
import {
    Container,
    Box,
    Avatar,
    Button,
    IconButton, createFilterOptions, Link, Typography, TextField,
    FormControlLabel, Switch, Fab, Chip, Stack
} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { SpeakText } from "../../helper/SpeakText";

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { sbUpsert, earliestNextQuestion } from '../../helper/SupabaseController';
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'

import Markdown from '../../parts/Markdown';
import ConfirmDialog from "../../parts/ConfirmDialog";
import { loadSettings } from '../settings/LocalSettings';

import {
    useParams
} from 'react-router-dom';
import TagsInput from '../../parts/TagsInput';
import { markdownReplace } from '../../parts/Markdown/MarkdownReplace';
import DialogButtonForInput from '../../parts/Markdown/DialogButtonForInput';
import { Copyright } from '../../parts/Copyright';
import { addTagText } from '../../helper/TagUtil';
import Loading from '../../parts/Loading';


const Create = React.forwardRef((props, ref) => {
    const { id, front, back, tags, correct, wrong, frontimage, backimage } = useParams();

    //辞書から作成するときはローカルストレージから読み取る。
    const [cardText, setCardText] = React.useState(localStorage.getItem('dictonarytocards') || "")

    const [needFetch, setNeedFetch] = React.useState(false);
    const popupConfirmRef = React.useRef();
    const { user } = useAuth();
    const [state, setState] = React.useState()
    const [settings, setSettings] = React.useState(loadSettings())


    React.useEffect(() => {
        console.log("cardUseEffect")
        if (id || props.id) {
            setNeedFetch(true)
        } else if (cardText) {
            //辞書から作成するときはローカルストレージから読み取る。
            const cardState = cardText.split("\t") || cardText
            setState({
                front_side: cardState[0],
                back_side: "",
                tags: [""],
                wrong_options: [],
                correct_options: cardState[1].split(','),
                front_image: "",
                back_image: "",
                created_by: user.id,
                priority: true
            })
        } else {
            setState((state) => ({
                ...state,
                front_side: front || "",
                back_side: back || "",
                tags: tags?.split(',') || [],
                wrong_options: wrong?.split(',') || [],
                correct_options: correct?.split(',') || [],
                front_image: frontimage || "",
                back_image: backimage || "",
                created_by: user.id,
                priority: true
            })
            )
        }
    }, [user.id, id, front, back, tags, correct, wrong, frontimage, backimage, props.id, cardText])

    const initializeTheState = () => {
        setState({
            front_side: "",
            back_side: "",
            tags: [],
            wrong_options: [],
            correct_options: [],
            front_image: "",
            back_image: "",
            created_by: user.id,
            priority: true
        })
    }

    const fetchData = async (card_id) => {
        const { data, error } = await supabase
            .from("card")
            .select("*")
            .eq("card_id", card_id)
            .single();

        // .eq(filterColumn, filterValue)
        //.order("card_id", { ascending: false });
        setNeedFetch(false)
        setState((state) => ({
            ...state,
            card_id: data?.card_id || "",
            front_side: data?.front_side || "",
            back_side: data?.back_side || "",
            tags: data?.tags === "" ? [] : data?.tags?.split(',') || [],
            wrong_options: data?.wrong_options === "" ? [] : data?.wrong_options?.split(',') || [],
            correct_options: data?.correct_options === "" ? [] : data?.correct_options?.split(',') || [],
            front_image: data?.front_image || "",
            back_image: data?.back_image || "",
            created_by: data?.created_by,
            priority: true
        })
        )
        if (error) { toast.error(error.message, ToastStyle.error) }
        return { data, error };
    }

    const { isLoading, error } = useQuery(
        ["cord_id", (id ? id : props.id)], () => fetchData((id ? id : props.id)),
        {
            enabled: needFetch,
        }
    )

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;

    const handleChange = (e) => {
        e.preventDefault();
        setState((state) => ({ ...state, [e.target.id]: e.target.value }));
    };
    const handleKatexChange = (id, value) => {
        setState((state) => ({ ...state, [id]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const res = await popupConfirmRef.current.confirm();
        const { data: nextQuestion, error: nextQuestionTimeError } = await earliestNextQuestion("card", user)
        !nextQuestion && toast.error(nextQuestionTimeError.message, ToastStyle.error)

        if (!res) { return; }
        console.log(state)
        let newBody = {
            card_id: state?.card_id || uuidv4(),
            front_side: state?.front_side || "",
            back_side: state?.back_side || "",
            correct_options: state?.correct_options.join(",") || "",
            wrong_options: state?.wrong_options.join(",") || "",
            tags: state?.tags.join(",") || "",
            front_image: state?.front_image || "",
            back_image: state?.back_image || "",
            created_by: state?.created_by,
            next_question_date_time: state.priority ? nextQuestion.next_question_date_time : null
        }
        const newTopTags = addTagText(settings.toptags, state.tags)
        settings.toptags = newTopTags
        // setState((state) => ({ ...state, wrong_options: item }))
        localStorage.setItem('settings', JSON.stringify(settings));
        localStorage.removeItem("dictonarytocards")

        // state.priority && newBody.push({ next_question_date_time: new Date() })
        const { error } = await sbUpsert("card", newBody)
        !error ? initializeTheState() : toast.error(error.message, ToastStyle.error)
    };
    return (
        <Container component="main" maxWidth="xs">
            <Toaster />
            <Box
                component="form" onSubmit={handleSubmit}
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <NoteAddIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    カード作成・編集
                </Typography>
                <TextField
                    sx={{ display: 'block' }}
                    margin="normal"
                    required={true}
                    fullWidth
                    id="front_side"
                    name="front_side"
                    label="Front side"
                    multiline
                    // rows={2}
                    autoFocus
                    // defaultValue={state?.front_side || ""}
                    value={state?.front_side || ""}

                    onChange={handleChange}
                    InputProps={{
                        endAdornment:
                            <>
                                <IconButton color="primary" aria-label="add" size="small"
                                    onClick={() =>
                                        // const spText = markdownReplace(state?.front_side)
                                        SpeakText(state?.front_side || "", settings.speech.lang, settings.speech.voice, settings.speech.rate)
                                    }>
                                    <VolumeUpIcon />
                                </IconButton>
                                <DialogButtonForInput
                                    value={state?.front_side}
                                    onChange={(res) => handleKatexChange("front_side", res)}
                                    renderInput={(params) => (
                                        <IconButton color="primary"  {...params}>
                                            <DesignServicesIcon />
                                        </IconButton>
                                    )} />
                            </>


                    }}

                />
                {/* <Markdown children={"$\\frac{a+b}{c+d}$"} /> */}
                <Markdown children={state?.front_side} />
                <TagsInput
                    margin="normal"
                    onChange={(item) => (
                        setState((state) => ({ ...state, correct_options: item }))
                    )}
                    fullWidth
                    variant="outlined"
                    id="correct_options"
                    tags={state?.correct_options}
                    name="correct_options"
                    placeholder="正解を入力してEnterで確定"
                    label="correct_options"
                />

                <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    id="back_side"
                    name="back_side"
                    label="Discription"
                    // defaultValue={back}
                    value={state?.back_side || ""}

                    onChange={handleChange}
                    InputProps={{
                        endAdornment:
                            <>
                                <DialogButtonForInput
                                    value={state?.back_side}
                                    onChange={(res) => handleKatexChange("back_side", res)}
                                    renderInput={(params) => (
                                        <IconButton color="primary"  {...params}>
                                            <DesignServicesIcon />
                                        </IconButton>
                                    )} />
                            </>


                    }}
                />
                <Markdown children={state?.back_side} />

                <Typography component="h5" sx={{ mt: 8, mb: 1 }}>
                    Options
                </Typography>
                <TagsInput
                    margin="normal"
                    onChange={(item) => (
                        setState((state) => ({ ...state, "tags": item }))
                    )}
                    fullWidth
                    variant="outlined"
                    id="tags"
                    tags={state?.tags}
                    name="tags"
                    placeholder="タグを入力してEnterで確定"
                    label="tags"
                />
                <Stack fullWidth direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                    {settings.toptags.map((value, key) => {
                        if (value.tagname === "") return null
                        return (
                            <Chip
                                key={key}
                                label={value.tagname}
                                onClick={(value) => {
                                    console.log(value.target.textContent)
                                    setState((state) => ({ ...state, "tags": [...state.tags, value.target.textContent] }))

                                }}
                            />

                        )
                    })}
                </Stack>

                <TagsInput
                    margin="normal"
                    onChange={(item) => (
                        setState((state) => ({ ...state, wrong_options: item }))
                    )}
                    fullWidth
                    variant="outlined"
                    id="wrong_options"
                    tags={state?.wrong_options}
                    name="wrong_options"
                    placeholder="誤答を入力してEnterで確定"
                    label="wrong_options"
                />

                <FormControlLabel
                    control={
                        <Switch id="priority"
                            checked={state?.priority}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    [event.target.id]: event.target.checked,
                                });
                            }}

                        // inputProps={{ 'aria-label': 'controlled' }}

                        />}
                    label="優先学習" />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>

            </Box>
            <Copyright />
            <ConfirmDialog message={"保存しますか？"} ref={popupConfirmRef} />
        </Container >
    )
})

export default Create

