import React from 'react'
import {
    Grid, IconButton, TextField,
    Chip,
    Box,
    Typography, Fab, Container
} from '@mui/material';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';
import Looks5OutlinedIcon from '@mui/icons-material/Looks5Outlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { SpeakText } from "../../helper/SpeakText";
import ReSkilling from './ReSkilling';
import { loadSettings } from '../settings/LocalSettings'
import Loading from '../../parts/Loading';
import { useNavigate } from "react-router-dom"
import Markdown from '../../parts/Markdown';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DialogButtonForCreate from '../create/DialogButtonForCreate';
const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
const genRandArry = (num, length) => {
    //ランダムな9までの5個の配列を生成
    // var num = 10;
    // var length = 5
    var arr = [];
    for (var i = 0; i < length; i++) {
        arr.push(Math.floor(Math.random() * num));
    }
    return arr;
}

// eslint-disable-next-line
function getStrDate(date, format) {
    format = format.replace(/YYYY/, date.getFullYear());
    format = format.replace(/MM/, date.getMonth() + 1);
    format = format.replace(/DD/, date.getDate());
    format = format.replace(/hh/, date.getHours());
    format = format.replace(/mm/, date.getMinutes());
    format = format.replace(/ss/, date.getSeconds());
    return format;
}
// eslint-disable-next-line
function getUTCStrDate(date, format) {
    format = format.replace(/YYYY/, date.getUTCFullYear());
    format = format.replace(/MM/, date.getUTCMonth() + 1);
    format = format.replace(/DD/, date.getUTCDate());
    format = format.replace(/hh/, date.getUTCHours());
    format = format.replace(/mm/, date.getUTCMinutes());
    format = format.replace(/ss/, date.getUTCSeconds());
    return format;
}

function getDateDiff(date) {
    const nowDate = new Date()
    const diffTime = nowDate.getTime() - date.getTime();
    if (diffTime > 1000 * 60 * 60 * 24) {
        return `${Math.floor(diffTime / (1000 * 60 * 60 * 24))}日前`;
    } else if (diffTime > 1000 * 60 * 60) {
        return `${Math.floor(diffTime / (1000 * 60 * 60))}時間前`;
    } else if (diffTime > 1000 * 60) {
        return `${Math.floor(diffTime / (1000 * 60))}分前`;
    } else {
        return `${Math.floor(diffTime / (1000))}秒前`
    }

}
const MultipleChoiceExam = ({ card, options, onChange, progress }) => {
    // const [value, setValue] = React.useState(0);
    const [clickCounter, setClickCounter] = React.useState(0);
    const [selectValue, setSelectValue] = React.useState()
    const [correct, setCorrect] = React.useState()
    const [inquiryOptions, setInquiryOptions] = React.useState()
    const [result, setResult] = React.useState()
    // const settings = JSON.parse(localStorage.getItem('settings'));
    const [settings, setSettings] = React.useState(loadSettings())
    const navigate = useNavigate()

    const speak = () => {
        SpeakText(card?.front_side || "", settings.speech.lang, settings.speech.voice, settings.speech.rate)

    }
    SpeakText("", "en-US")

    React.useEffect(() => {
        // 正解の配列を生成　
        // const correct_options = [...card?.correct_options?.split(",") || "", card?.back_side || ""]
        const correct_options = [...card?.correct_options?.split(",") || ""]
            .filter((item) => {
                return item !== ''
            })
        // 正解から１つだけランダムに取得
        const correctOpNum = genRandArry(Object.keys(correct_options).length, 1)
        const tempCorrect = correct_options[correctOpNum[0]] || "正解"


        const designated_wrong_options = [...card?.wrong_options?.split(",") || ""]
            .filter((item) => {
                return item !== ''
            })
        console.log("designated_wrong_options", designated_wrong_options)

        // 選択肢の中から正解と一致する選択肢を削除
        // https://qiita.com/Kazuhiro_Mimaki/items/f3df5e65ca97b62c70cd
        const tempWrong_options = shuffle(options.filter(i => correct_options.indexOf(i) === -1))

        const wrong_options = designated_wrong_options.length === 0 ? tempWrong_options : designated_wrong_options

        // 正解を含む４つの選択肢を作成
        // setInquiryOptions(
        //     shuffle([
        //         tempCorrect, tempWrong_options[0], tempWrong_options[1], tempWrong_options[2]
        //     ])
        // )
        const tempOptions = shuffle([
            tempCorrect, ...wrong_options.map((item, index) =>
                index <= 2 &&
                item).filter(v => v)
        ])
        setInquiryOptions([...tempOptions, "❌"]

        )
        setCorrect(tempCorrect)
        SpeakText(card?.front_side || "", settings.speech.lang, settings.speech.voice, settings.speech.rate)

    }, [card?.correct_options, options, card?.wrong_options, card?.front_side, settings.speech.lang, settings.speech.voice, settings.speech.rate])


    // 意地でもconst 使う
    // https://qiita.com/talog/items/ea7ff725dd02319f912b
    const inquiryButton = (flag) => {
        switch (flag) {
            case 0: return <LooksOneOutlinedIcon sx={{ fontSize: 40 }} />
            case 1: return <LooksTwoOutlinedIcon sx={{ fontSize: 40 }} />
            case 2: return <Looks3OutlinedIcon sx={{ fontSize: 40 }} />
            case 3: return <Looks4OutlinedIcon sx={{ fontSize: 40 }} />
            case 4: return <Looks5OutlinedIcon sx={{ fontSize: 40 }} />
            default: return <LooksOneOutlinedIcon sx={{ fontSize: 40 }} />
        }
    };
    const mark = (value) => {
        if (clickCounter === 1) {
            if (value === correct) {
                return <CheckIcon sx={{ fontSize: 40 }} />
            } else if (value === selectValue) {
                return <ClearIcon sx={{ fontSize: 40 }} />
            }
        }
    }

    const handleOnclieck = (value) => {
        setSelectValue(value)
        if (clickCounter === 0) {
            setClickCounter(1)
            setResult((value === correct) ? true : false)
            speak()
            if (value === correct) {
                console.log("正解")
            } else {
                console.log("不正解")
            }
            onChange({ 
                result: ((value === correct) ? true : false),
                correct: correct,
                selectValue: value,
                inquiryOptions: inquiryOptions,
                card: card ,cardUpdate:false})

        } else {
            setClickCounter(0)
            onChange({ 
                result: result, 
                correct: correct, 
                selectValue: selectValue, 
                inquiryOptions: inquiryOptions, 
                card: card ,cardUpdate:true})
        }
    }

    const getTextFeildWidh = (str) => {
        // switch ((str || "").length) {
        //     case (0 < 40): return "h3"
        //     case (30 < 1000): return "h6"
        //     default: return "h6"
        // }
        const len = (str || "").length
        if (0 <= len && len <= 15) {
            return "h3"
        } else if (16 <= len && len <= 20) {
            return "h4"
        } else if (21 <= len && len <= 2000) {
            return "h6"
        }
    };

    if (!correct) {
        return <Loading />
    } else {
        // speak()

    }
    //https://mui.com/material-ui/react-grid/
    return (
        <Box>
            <Grid
                sx={{ mt: 0 }}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                {/* BUG
                FIXME
                HACK
                - [ ] Do something
                TODO そのうち実装 react-markdown
                TODO react-simplemde-editor */}
                <Grid item xs={12} width={"100%"}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}  >
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowCircleLeftIcon color="primary" size='small' />
                            </IconButton>
                        </Grid>

                        <Grid item xs={1}  >
                            <Typography variant="body1" textAlign="center">
                                {card?.mistake === true ? <ErrorOutlineIcon fontSize="inherit" /> : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}  >
                            <Typography variant="body1" textAlign="center">
                                {progress}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}  >
                            <Typography variant="body1" textAlign="center">
                                {card?.question_interval}
                            </Typography>
                        </Grid>

                    </Grid>

                    <Typography variant="body2" textAlign="center">
                        {/* {getStrDate(new Date(card?.updated_at), "YYYY年MM月DD日 hh時mm分ss秒")} */}
                        {clickCounter === 0 ?
                            getDateDiff(new Date(card?.updated_at)) :
                            "　"
                        }
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={2}  >
                            <Fab color="primary" size='small'
                                onClick={() => speak()}>
                                <VolumeUpIcon />
                            </Fab>
                        </Grid>
                        <Grid item xs={10}
                            container
                            direction="row" justify="flex-end" >
                            {/* <Typography variant={
                                getTextFeildWidh(card?.front_side || "")
                            } textAlign="left" sx={{ p: 2 }}>
                                {card?.front_side || ""}
                            </Typography> */}
                            <Markdown children={card?.front_side || ""} />
                        </Grid>
                    </Grid>

                </Grid>
                {inquiryOptions &&
                    inquiryOptions.map((value, key) => (
                        <Grid item key={key} width={"100%"}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"

                            // onClick={() => handleOnclieck(value)}
                            >
                                <Grid item xs={2}>
                                    <IconButton
                                        color="primary"
                                        aria-label="add to shopping cart"
                                        onClick={() => handleOnclieck(value)}
                                    >
                                        {inquiryButton(key)}

                                    </IconButton>

                                    {/* {(value === correct) ?
                                        <IconButton
                                            color="primary"
                                            aria-label="add to shopping cart"
                                            onClick={() => handleOnclieck(value)}
                                        >
                                            {inquiryButton(key)}
                                        </IconButton>
                                        :

                                        <ReSkilling
                                            // isDisabled={false}
                                            value={value}
                                            card={card}
                                            handleOnclieck={() => handleOnclieck}
                                            onChange={(res) => {
                                                handleOnclieck(res)
                                            }}
                                            renderInput={(params) =>
                                                <IconButton
                                                    color="primary"
                                                    variant="standard"
                                                    {...params}
                                                >
                                                    {inquiryButton(key)}
                                                </IconButton>}
                                        />
                                    } */}

                                    


                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6" textAlign="left">
                                        {value || ""}
                                    </Typography>
                                </Grid>
                                {clickCounter === 1 &&
                                    <Grid item xs={2}>
                                        {mark(value)}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    )
                    )
                }
                {/* <Grid item width={"100%"}>
                    <Grid item xs={12}>
                        <ReSkilling
                            isDisabled={false}
                            handleOnclieck={handleOnclieck}
                            onChange={(res) => {
                                console.log("")
                            }}
                            renderInput={(params) =>
                                <IconButton
                                    variant="standard"
                                    {...params} >
                                    aaa
                                </IconButton>}
                        />
                    </Grid>


                </Grid> */}
            </Grid>
            <Grid
                sx={{ mt: 10 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {clickCounter === 1 &&
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12} >
                            {/* <Typography variant="h4" textAlign="left">
                                {card?.back_side || ""}
                            </Typography> */}
                            <Markdown children={card?.back_side || ""} />

                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h5" textAlign="left">
                                {card?.correct_options || ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            {(card?.tags || "").split(",").map((value, idx) => (
                                (value !== "")
                                &&
                                (< Chip key={idx} label={value} variant="outlined" size="small" />)
                            ))}
                        </Grid>
                        <DialogButtonForCreate
                            item={card}
                            renderInput={(params) => (
                                <IconButton
                                    color="primary"
                                    variant="standard"
                                    {...params}
                                >
                                    <CreateIcon {...params} />
                                </IconButton>
                            )} />

                        {/* TODO 履歴を追加する。 */}
                    </Grid>
                }
            </Grid>

            {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
                    <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
                    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
                </BottomNavigation>
            </Paper> */}
        </Box >

    )
}

export default React.memo(MultipleChoiceExam)