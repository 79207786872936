import { markdownReplace } from "../parts/Markdown/MarkdownReplace";
// import axios from 'axios';

export function SpeakText2(speakText, argLang = "en-US", argVoice = "Samantha", rate = 1) {
  let speak = new SpeechSynthesisUtterance();
  let voice = speechSynthesis.getVoices().find(function (voice) {
    return voice.name === argVoice;
  });
  // 取得できた場合のみ適用する
  if (voice) {
    speak.voice = voice;
  } else {
    // 英語に対応しているvoiceを設定
    const voices = speechSynthesis.getVoices();
    for (let i = 0; i < voices.length; i++) {
      if (voices[i].lang === argLang) {
        speak.voice = voices[i];
      }
    }
  }
  console.log("speak", speakText, markdownReplace(speakText))
  speak.text = markdownReplace(speakText)
  speak.rate = rate; // 読み上げ速度 0.1-10 初期値:1 (倍速なら2, 半分の倍速なら0.5, )
  speak.pitch = 1; // 声の高さ 0-2 初期値:1(0で女性の声)
  //speak.lang = "en-US"; //(日本語:ja-JP, アメリカ英語:en-US, イギリス英語:en-GB, 中国語:zh-CN, 韓国語:ko-KR)
  speechSynthesis.speak(speak);
}

export const SpeakText = async (speakText, argLang = "en-US", argVoice = "Samantha", rate = 1) => {
  const apiKey = process.env.REACT_APP_GOOGLE_TEXT_TO_SPEECH_KEY;
  const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

  const data = {
    input: { "text": speakText },
    voice: { languageCode: 'en-US', name: 'en-US-Standard-H' },
    audioConfig: { audioEncoding: 'MP3' },
  };
  const otherparam = {
    headers: {
      "content-type": "application/json; charset=UTF-8"
    },
    body: JSON.stringify(data),
    method: "POST"
  }
  fetch(url, otherparam)
    .then(data => {
      return data.json()
    })
    .then(res => {
      try {
        var blobUrl = base64ToBlobUrl(res.audioContent)
        var audio = new Audio()
        audio.src = blobUrl
        audio.play()
      } catch (e) {
        console.log(e)
      }
    })
    .catch(error => alert(error))

  // try {
  //   const response = await axios.post(url, data);
  //   const audioContent = response.data.audioContent;
  //   const audioBlob = new Blob([Uint8Array.from(atob(audioContent), c => c.charCodeAt(0))], { type: 'audio/mp3' });
  //   const audioUrl = URL.createObjectURL(audioBlob);
  //   setAudioUrl(audioUrl);
  // } catch (error) {
  //   console.error('Error:', error);
  // }


}
// Base64 → BlobUrl
function base64ToBlobUrl(base64) {
  var bin = window.atob(base64.replace(/^.*,/, ''))
  var buffer = new Uint8Array(bin.length)
  for (var i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  return window.URL.createObjectURL(new Blob([buffer.buffer], { type: "audio/wav" }))
}