import React from 'react'
import {
    Container,
    Paper, ToggleButton, ToggleButtonGroup,
    TextField,
    Typography
} from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Crop169Icon from '@mui/icons-material/Crop169';
import Markdown from '.'

const MarkdownInputHelper = React.forwardRef((props, ref) => {
    const { onChange, value } = props;
    const [state, setState] = React.useState({ "value": value })

    const katexArray = [
        "$\\frac{a}{b}$",
        "$\\sqrt{x}$",
        "$\\begin{cases}\ny=3x^2\\\\\ny=x^2+6\n\\end{cases}$",
        // "$\\tan \\theta = \\dfrac{\\sin \\theta}{\\cos \\theta}$",
    ]
    const handleChange = (e) => {
        e.preventDefault();
        setState((state) => ({ ...state, [e.target.id]: e.target.value }));
        onChange({ ...state, [e.target.id]: e.target.value })
    };
    const handleOnClick = (event, value) => {
        var area = document.getElementById("value");
        const newValue = area.value.substr(0, area.selectionStart)
            + value
            + area.value.substr(area.selectionStart);
        setState((state) => ({ ...state, "value": newValue }));
        onChange({ ...state, "value": newValue })
    }

    return (
        <Container component="main" maxWidth="xs">


            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                }}
            >
                <ToggleButtonGroup
                    size="small"
                    fullWidth
                    onChange={handleOnClick}
                    aria-label="text alignment"
                >
                    <ToggleButton value="**B**" aria-label="bold" key="**B**">
                        <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="*I*" aria-label="italic" key="*I*">
                        <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="<u>U</u>" aria-label="underlined">
                        <FormatUnderlinedIcon />
                    </ToggleButton>
                    <ToggleButton value="<s>T</s>" aria-label="underlined">
                        <FormatStrikethroughIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    size="small"
                    fullWidth
                    onChange={handleOnClick}
                    aria-label="text alignment"
                >
                    <ToggleButton value="- 項目1" aria-label="underlined">
                        <FormatListBulletedIcon />
                    </ToggleButton>
                    <ToggleButton value="`  `" aria-label="underlined">
                        <Crop169Icon />
                    </ToggleButton>
                    <ToggleButton value="# h1" aria-label="underlined">
                        <Typography variant='body1' >
                            h1
                        </Typography>
                    </ToggleButton>
                    <ToggleButton value="## h2" aria-label="underlined">
                        <Typography variant='body1' >
                            h2
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    exclusive
                    fullWidth
                    onChange={handleOnClick}
                    aria-label="text alignment"
                >
                    {katexArray &&
                        katexArray.map((item) => {
                            return (
                                <ToggleButton value={item} id={item} key={item}>
                                    <Markdown children={item} />
                                </ToggleButton>
                            )

                        })}

                </ToggleButtonGroup>

            </Paper>
            <TextField
                margin="normal"
                fullWidth
                multiline
                id="value"
                name="value"
                label="Discription"
                // defaultValue={back}
                value={state?.value || ""}

                onChange={handleChange}
            />
            <Markdown children={state?.value} />
        </Container>
    )
})

export default MarkdownInputHelper