import React from 'react'
import {
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Fab, AppBar, IconButton, Toolbar, Typography,
    Stack
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Markdown from '.';
import MarkdownInputHelper from './MarkdownInputHelper';
import { ReactComponent as MarkdownIcon } from "./MarkdownIcon.svg";
import { useNavigate } from "react-router-dom"

const DialogButtonForInput = (props) => {
    const componentRef = React.useRef();
    const [open, setOpen] = React.useState(false);
    const { value, renderInput, onChange } = props;
    const [state, setState] = React.useState()
    const navigate = useNavigate()
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const handleConfirm = () => {
        onChange(state)
        setOpen(false);

    }
    const handleChange = (value) => {
        setState(value.value);
    };

    return (
        <div>
            {renderInput({ onClick: () => handleClickOpen() })}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close" >
                            <CloseIcon />
                        </IconButton>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                            <IconButton
                                onClick={() => window.open("https://ja.wikipedia.org/wiki/Markdown#%E8%A8%98%E6%B3%95%E3%81%AE%E4%BE%8B", '_blank')} >
                                <MarkdownIcon margin={30} width={48} height={48} fill={"white"} />
                            </IconButton>
                            <IconButton
                                size={"small"}
                                onClick={() => window.open("https://katex.org/docs/supported.html/", '_blank')} color={"inherit"}>
                                <Markdown children={"$\\KaTeX$"} />
                            </IconButton>
                        </Stack>

                        {/* 
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {`${value}`}
                        </Typography> */}
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {/* <Create ref={componentRef} id={item?.card_id} /> */}
                    <MarkdownInputHelper ref={componentRef} onChange={handleChange} value={value} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        確定
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog >
        </div >)
}

export default React.memo(DialogButtonForInput)