import React from 'react'
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import { useAuth } from '../auth/AuthProvider';
import Loading from '../../parts/Loading';

import {
    useTheme,
    Box,
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Typography
} from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import pictureWorkout from "./material/workout.jpg"

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const Material = ({ cards, title = "", onChange }) => {
    const navigate = useNavigate()
    // console.log(cards)
    const handleOnclick = () => {
        onChange(cards)
        localStorage.setItem('cards', JSON.stringify(cards));
        navigate(`/workout/benche${""}`);
    }
    return (
        <Card sx={{ display: 'flex', mt: 5, justifyContent: "space-between" }} >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto', width: "100%" }}>
                    <Typography component="div" variant="h5">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {Object.keys(cards).length || "-"}
                    </Typography>
                </CardContent>

                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    {Object.keys(cards).length > 0 ?

                        <IconButton aria-label="play/pause" onClick={handleOnclick} >
                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                        </IconButton>
                        : <></>}

                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                // image={pictureWorkout}
                image={`https://source.unsplash.com/featured/?pencil/100x10${getRandomInt(9)}`}
                alt="Live from space album cover"
            />

            {/* https://donsyoku.com/website/about-unsplash.html */}
        </Card >)
}

export default Material