import React from 'react'
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
import { supabase } from '../../supabaseClient'
import { Box, Container } from '@mui/material'


const Login = () => {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* <Auth
                    supabaseClient={supabase}
                    magicLink
                    redirectTo="/"
                /> */}
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    onlyThirdPartyProviders={true}
                    providers={['google']}
                />
            </Box>
        </Container>

    )
}

export default Login