import React from 'react'
import {
    Container,
    Box,
    Avatar,
    IconButton,
    Paper,
    InputBase,
    List, ListItem, ListItemButton, ListItemText,
    createFilterOptions, Typography, TextField, TablePagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useQuery } from "react-query";
import Loading from '../../parts/Loading';
import { useNavigate } from "react-router-dom"

import ConfirmDialog from "../../parts/ConfirmDialog";
import { Copyright } from '../../parts/Copyright';

const filter = createFilterOptions();
const Dictionary = () => {
    // TODO 辞書データを更新する。word と tranceration　のみ抜き出す。wordnet.tsv
    const filePath = "./dictionary.txt"
    const [needFetch, setNeedFetch] = React.useState(true);
    const popupConfirmRef = React.useRef();
    const navigate = useNavigate()
    const [state, setState] = React.useState()
    const [search, setSearch] = React.useState()
    const [page, setPage] = React.useState(0)
    const [count, setCount] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleClickEn()
        window.scrollTo(0, 0)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        handleClickEn()
        window.scrollTo(0, 0)
    };

    const fetchData = async (card_id) => {
        const response = await fetch(filePath);
        const data = await (await response.text()).split("\n");
        // handleClickEn()
        return { data };
    }

    const { isLoading, error, data } = useQuery(
        ["dict", "id"], () => fetchData("id"),
        {
            enabled: needFetch
        }
    )

    if (isLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (!data) { return <Loading />; }

    const handleClickEn = async (e) => {
        const res = data["data"].map((value, key) => (
            // value.split("\t")[0].includes(search) && value
            value.split("\t")[0].startsWith(search) && value
        )).filter(v => v)
        setCount(res.length)
        setState(res.slice(0 + rowsPerPage * page, rowsPerPage * page + rowsPerPage))
    }
    const handleClickJp = async (e) => {
        const res = data["data"].map((value, key) => (
            // value.split("\t")[0].includes(search) && value
            value.split("\t")[1]?.includes(search) && value
        )).filter(v => v)
        setState(res.slice(0 + rowsPerPage * page, rowsPerPage * page + rowsPerPage))
    }
    const handleSearchInputChange = (e) => {
        setPage(0)
        setSearch((search) => (e.target.value));
    }


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <ManageSearchIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    検索
                </Typography>
                <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "xs" }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="検索"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={handleSearchInputChange}
                        onKeyPress={e => {
                            // e.keyCodeは常に0になる
                            if (e.key === 'Enter') {
                                // エンターキー押下時の処理
                                e.preventDefault()
                                handleClickEn()
                            }
                        }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClickEn}>
                        <SearchIcon />
                    </IconButton>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClickJp}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <Box sx={{ mt: 5, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <List >
                        {state && state.map((item, key) =>
                        (
                            <ListItem disablePadding key={key}>
                                <ListItemButton
                                    // onClick={() => navigate(`/dictionarytocard/text=${item}`, { state: { id: 1 } })}>
                                    onClick={() => {
                                        localStorage.setItem('dictonarytocards', item);
                                        navigate(`/create`);
                                    }}
                                >

                                    <ListItemText primary={`${item}`} />
                                </ListItemButton>
                            </ListItem>
                        )


                        )}
                    </List>
                </Box>

            </Box>
            <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmDialog message={"保存しますか？"} ref={popupConfirmRef} />
            <Copyright />
        </Container>)
}

export default Dictionary