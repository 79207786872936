import React from 'react'
import { v4 as uuidv4 } from "uuid";
import { useAuth } from '../auth/AuthProvider';
import {
    Container,
    Box,
    Avatar,
    FormControlLabel, Switch,
    Checkbox,
    Button,
    InputAdornment,
    Autocomplete, createFilterOptions, Link, Typography, TextField
} from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { sbUpsert, sbDelete } from '../../helper/SupabaseController';


import ConfirmDialog from "../../parts/ConfirmDialog";

import {
    BrowserRouter as Router,
    useParams,
    useNavigate,
} from 'react-router-dom';
const filter = createFilterOptions();

const DictionaryToCard = () => {
    const { id, front, back, tags, correct, wrong, frontimage, backimage } = useParams();
    const [cardText, setCardText] = React.useState(localStorage.getItem('dictonarytocards') || "")
    const popupConfirmRef = React.useRef();
    const { user } = useAuth();
    const [state, setState] = React.useState()
    const navigate = useNavigate();

    React.useEffect(() => {
        const cardState = cardText.split("\t") || cardText
        setState({
            front_side: cardState[0],
            back_side: "",
            tags: ["English"],
            wrong_options: [],
            correct_options: cardState[1].split(','),
            front_image: "",
            back_image: "",
            created_by: user.id,
            priority: true
        })
    }, [cardText, user.id])

    const handleChange = (e) => {
        setState((state) => ({ ...state, [e.target.id]: e.target.value }));
    };

    const handleSubmit = async (event, newValue) => {
        event.preventDefault();
        const res = await popupConfirmRef.current.confirm();
        if (!res) { return; }
        console.log(state)
        const { error, data } = await sbUpsert("card", {
            card_id: state?.card_id || uuidv4(),
            front_side: state?.front_side || "",
            back_side: state?.back_side || "",
            correct_options: state?.correct_options.join(",") || "",
            wrong_options: state?.wrong_options.join(",") || "",
            tags: state?.tags.join(",") || "",
            front_image: state?.front_image || "",
            back_image: state?.back_image || "",
            created_by: state?.created_by,
            next_question_date_time: state.priority ? new Date() : null
        })

        localStorage.removeItem("dictonarytocards")
        !error ? navigate(-1) : toast.error(error.message, ToastStyle.error)
    };


    return (
        <Container component="main" maxWidth="xs">
            <Toaster />
            <Box
                component="form" onSubmit={handleSubmit}
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: 'xs'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <NoteAddIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    新規登録
                </Typography>
                {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
                <TextField
                    margin="normal"
                    required={true}
                    fullWidth
                    id="text"
                    name="text"
                    label="text"
                    multiline
                    rows={4}
                    autoFocus
                    // defaultValue={state?.front_side || ""}
                    value={cardText || ""}

                    onChange={(e) => {
                        setCardText(e.target.value)
                        const cardState = cardText.split("\t") || cardText
                        console.log(cardState)
                        setState((state) => ({
                            ...state,
                            front_side: cardState[0],
                            correct_options: cardState[1].split(',')
                        }));

                    }}
                />
                <TextField
                    margin="normal"
                    required={true}
                    fullWidth
                    id="front_side"
                    name="front_side"
                    label="Front side"
                    multiline
                    rows={2}
                    autoFocus
                    // defaultValue={state?.front_side || ""}
                    value={state?.front_side || ""}

                    onChange={handleChange}
                />

                <Autocomplete sx={{ mt: 2 }}
                    multiple
                    fullWidth
                    id="correct_options"
                    name="correct_options"
                    options={state?.correct_options || []}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    //defaultValue={value}
                    value={state?.correct_options || []}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setState((state) => ({ ...state, correct_options: newValue }));
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setState((state) => ({ ...state, correct_options: newValue.inputValue }));

                        } else {
                            setState((state) => ({ ...state, correct_options: newValue }));

                        }
                    }}
                    // filterSelectedOptions
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push(
                                `${inputValue}`
                            );
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Correct options"
                            placeholder=""

                        />
                    )}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="back_side"
                    name="back_side"
                    label="Discription"
                    // defaultValue={back}
                    value={state?.back_side || ""}

                    onChange={handleChange}
                />
                <Typography component="h5" sx={{ mt: 8, mb: 1 }}>
                    Options
                </Typography>
                <Autocomplete
                    multiple
                    fullWidth
                    id="tags"
                    name="tags"
                    options={state?.tags || []}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    //defaultValue={value}
                    value={state?.tags || []}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setState((state) => ({ ...state, tags: newValue }));

                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setState((state) => ({ ...state, tags: newValue.inputValue }));

                        } else {
                            setState((state) => ({ ...state, tags: newValue }));
                        }
                    }}
                    // filterSelectedOptions
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push(
                                `${inputValue}`
                            );
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Tag"
                            placeholder=""
                        />
                    )}
                />

                <Autocomplete sx={{ mt: 2 }}
                    multiple
                    fullWidth
                    id="wrong_options"
                    name="wrong_options"
                    options={state?.wrong_options || []}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    //defaultValue={value}
                    value={state?.wrong_options || []}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setState((state) => ({ ...state, wrong_options: newValue }));
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setState((state) => ({ ...state, wrong_options: newValue.inputValue }));

                        } else {
                            setState((state) => ({ ...state, wrong_options: newValue }));

                        }
                    }}
                    // filterSelectedOptions
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push(
                                `${inputValue}`
                            );
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Wrong options"
                            placeholder=""
                        />
                    )}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="front_image"
                    name="front_image"
                    label="Front side image:  https://----"
                    value={state?.front_image || ""}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="back_image"
                    name="back_image"
                    label="Back side image:  https://----"
                    value={state?.back_image || ""}
                    onChange={handleChange}
                />
                <FormControlLabel
                    control={
                        <Switch id="priority"
                            value={state?.priority}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    [event.target.id]: event.target.checked,
                                });
                            }}

                        // inputProps={{ 'aria-label': 'controlled' }}

                        />}
                    label="優先学習" />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>

                {/* </Box> */}
            </Box>
            <ConfirmDialog message={"保存しますか？"} ref={popupConfirmRef} />
        </Container >)
}

export default DictionaryToCard