// import logo from "./logo.svg";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from '@mui/material/styles'

import Layout from "./components/Layout";
function App() {
	const queryClient = new QueryClient();
	const theme = createTheme()

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<Layout />

				</ThemeProvider>

			</QueryClientProvider>
		</div>
	);
}

export default App;
