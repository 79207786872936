import React from 'react'
import { useAuth } from "../auth/AuthProvider";
import {
    Container,
    Box,
    Avatar,
    FormControlLabel,
    Checkbox,
    Button,
    InputAdornment,
    Autocomplete, createFilterOptions, Link, Typography, TextField
} from '@mui/material'; import { supabase } from "../../supabaseClient";


const Dashboard = () => {
    const { signOut } = useAuth();
    async function singout(e) {
        e.preventDefault();
        supabase.auth.signOut()
    }
    return (

        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Dashboard
                </Typography>
                <Button
                    variant="contained"
                    onClick={singout}
                >
                    singout
                </Button>

            </Box>
        </Container>
    )
}

export default Dashboard