import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

} from 'chart.js';
import {
    Bar,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent
} from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Bar Chart - Stacked',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//             data: [100, 2100, 3, 4, 5, 6, 700],
//             backgroundColor: 'rgb(255, 99, 132)',
//         },
//         {
//             label: 'Dataset 2',
//             data: [3, 3, 3, 3, 3, 3, 400],
//             backgroundColor: 'rgb(75, 192, 192)',
//         },
//         {
//             label: 'Dataset 3',
//             data: [7, 6, 5, 4, 3, 2, 1],
//             backgroundColor: 'rgb(53, 162, 235)',
//         },
//     ],
// };


const Graph = (data) => {
    const chartRef = React.useRef(null);
    const printDatasetAtEvent = (dataset) => {
        if (!dataset.length) return;

        const datasetIndex = dataset[0].datasetIndex;

        console.log(data2.datasets[datasetIndex].label);
    };

    const printElementAtEvent = (element) => {
        if (!element.length) return;

        const { datasetIndex, index } = element[0];

        console.log(data2.labels[index], data2.datasets[datasetIndex].data[index]);
    };

    const printElementsAtEvent = (elements) => {
        if (!elements.length) return;

        console.log(elements.length);
    };
    const onClick = (event) => {
        const { current } = chartRef;

        if (!current) {
            return;
        }

        printDatasetAtEvent(getDatasetAtEvent(current, event));
        printElementAtEvent(getElementAtEvent(current, event));
        printElementsAtEvent(getElementsAtEvent(current, event));
    };
    const data2 = {
        labels: Object.values(data.data).map((e) => e.grouping_created_at),
        datasets: [
            {
                label: "復習",
                // data: Object.values(data).map((item) => item.count),
                data: Object.values(data.data).map((e) => e.count),
                backgroundColor: 'rgb(75, 192, 192)',
            }
        ]
    }
    return (
        <Bar
            ref={chartRef}
            onClick={onClick}
            options={options}
            data={data2} />
    )
}

export default Graph