import React from 'react'
import ReactMarkdown from 'react-markdown/';
// import ReactMarkdown from 'https://esm.sh/react-markdown@7'
import 'katex/dist/katex.min.css';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'


const Markdown = (props) => {
    if (!props.children) return null
    return (

        <ReactMarkdown
            // children={`The lift coefficient ($C_L$) is a dimensionless coefficient.`}
            // escapeHtml={false}
            children={props.children}
            remarkPlugins={[remarkMath, remarkGfm]}
            rehypePlugins={[rehypeKatex, rehypeRaw]}
        />


    );
}

export default Markdown