import React from 'react'
import { useQuery } from "react-query";
import { supabase } from '../../supabaseClient';
import { useAuth } from '../auth/AuthProvider';
import toast, { Toaster } from 'react-hot-toast';
import { ToastStyle } from '../../helper/ToastStyle'
import Loading from '../../parts/Loading';
import Material from './Material';

import {
    Box
} from '@mui/material';

import { loadSettings } from '../settings/LocalSettings';

const Agenda = () => {
    const { user } = useAuth()
    const [settings, setSettings] = React.useState(loadSettings())
    const [needFetch, setNeedFetch] = React.useState(false);
    const maxRows = 100
    const card_id = ""
    React.useEffect(() => {
        setNeedFetch(true)
    }, [])

    //useQuery を使ったFetchの正解！！！！
    const fetchData = async (card_id) => {
        const now = new Date();
        const { data: recentData, error } = await supabase
            .from("card")
            .select("*")
            .eq("created_by", user.id)
            .lt('next_question_date_time', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`)
            .limit(maxRows)
        // .eq(filterColumn, filterValue)
        //.order("card_id", { ascending: false });
        if (error) {
            toast.error(error.message, ToastStyle.error)
            return error;
        }
        //  else if (data) {
        //     console.log(data);
        //     setNeedFetch(false)
        //     return data;
        // }
        const recentDataRows = Object.keys(recentData).length
        if (recentDataRows === 0) { return recentData }
        const { data: scheduledData, error: error2 } = await supabase
            .from("card")
            .select("*")
            .eq("created_by", user.id)
            .is("history", null)
            .order("updated_at", { ascending: true })
            .limit(maxRows - recentDataRows)
        if (error2) {
            toast.error(error2.message, ToastStyle.error)
            return error;
        } else if (scheduledData) {
            console.log(scheduledData);
            setNeedFetch(false)
            const data = Object.assign(recentData, scheduledData);
            return data;
        }
    }
    const { isLoading, error, data } = useQuery(
        ["Quiz", card_id], () => fetchData(card_id),
        { enabled: needFetch }
    )
    //

    //useQuery を使ったFetchの正解！！！！
    const fetchDataHistory = async (card_id) => {
        const now = new Date();
        const { data, error } = await supabase
            .from("history")
            .select("*")
            .eq("created_by", user.id)
            // .eq('updated_at', `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}}`)
            // .rangeGt('updated_at', `[${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}} 00:00,${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate() + 1}} 00:00]`)
            // .gte('updated_at', `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 00:00`)
            // .gte('updated_at', `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`)
            .gte('updated_at', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}`)

            // .eq("type", "correct")
            .limit(10000)
        // .eq(filterColumn, filterValue)
        //.order("card_id", { ascending: false });
        if (error) {
            toast.error(error.message, ToastStyle.error)
            return error;
        } else if (data) {
            console.log(data);
            setNeedFetch(false)
            return data;
        }
    }
    const { isLoading: hisIsLoading, error: hisError, data: hisData } = useQuery(
        ["history", card_id], () => fetchDataHistory(card_id),
        { enabled: needFetch }
    )

    if (isLoading) return <Loading />;
    if (hisIsLoading) return <Loading />;
    if (error) return "An error has occurred: " + error.message;
    if (hisError) return "An error has occurred: " + hisError.message;
    if (!data) { return <Loading />; }
    if (!hisData) { return <Loading />; }

    const filteredCards = (tag) => {
        const cards = data && data?.filter((card) => {
            if (!tag?.trim()) return data;
            return `${card?.tags?.toLowerCase()}`.includes(tag.toLowerCase());
        });
        return cards
    }

    ///https://qiita.com/mtoutside/items/e34e06c674a8e6623ba4
    // 複数の特定要素のうちひとつでも当てはまったら true を返す
    const isIncludes = (arr, target) => arr.some(el => target.includes(el));
    // 複数の特定要素のうち全部当てはまったら true を返す
    const isAllIncludes = (arr, target) => arr.every(el => target.includes(el));

    const multipleFilters = (tags) => {
        const tagsLower = tags.map(value => value.toLowerCase())

        const cards = data && data?.filter((card) => {
            // if (!tag?.trim()) return data;
            return isAllIncludes(tagsLower, `${card?.tags?.toLowerCase()}`)
            // return `${card?.tags?.toLowerCase()}`.includes(tag.toLowerCase());
        });
        return cards
    }

    return (
        <Box maxWidth="xs" sx={{ mt: 5 }} width={"100%"}>
            <Toaster />
            今日の学習 {hisData.length}
            <Material cards={filteredCards("")} title={"復習カード"} onChange={() => console.log("")} />
            {/* {settings.Cardfilters.map((value, key) => (
                typeof value === 'string' &&
                filteredCards(value).length > 0 &&
                <Material cards={filteredCards(value)} title={value} onChange={() => console.log("")} key={key} />
            ))} */}

            {settings.cardfilters?.map((value, key) => (
                // typeof value === 'string' &&
                multipleFilters([value.text]).length > 0 &&
                <Material cards={multipleFilters([value.text])} title={value.text} onChange={() => console.log("")} key={key} />
            ))}
        </Box >
    )
}

export default Agenda